import { computed, defineComponent } from "vue";
import { DispatchType } from "@/interface/Cart";
import { useCartStore } from "@/store/cart/Cart";
import { useI18n } from "vue-i18n";
import DateTimeSelect from "@/ui-elements/input/date-time-select/DateTimeSelect.vue";
import { useSettingsStore } from "@/store/Settings";
export default defineComponent({
    name: "DateField",
    components: { DateTimeSelect },
    setup() {
        const { t: translate } = useI18n();
        const store = useCartStore();
        const settings = useSettingsStore().settings;
        const disabled = computed(() => store.currentCart.cart.type === DispatchType.None ||
            !Number(settings.order_ahead));
        return {
            translate,
            disabled,
            store,
        };
    },
});
