import * as Sentry from "@sentry/vue";
import { defineComponent, onUnmounted, ref, computed } from "vue";
import { useUserStore } from "@/store/User";
import { RiceCooker } from "@/riceCooker/riceCooker";
import axios from "axios";
import { websocket } from "@/services/WebsocketService";
import { dataHydration } from "@/services/DataHydrationService";
import ButtonBase from "@/ui-elements/button-base/ButtonBase.vue";
import { useMasterSlave } from "@/utils/useMasterSlave";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useSettingsStore } from "@/store/Settings";
import { useCallerId } from "@/utils/useCallerId";
import { devGetFrontendVersion, devGetRiceCookerVersion, } from "@/utils/devUtils";
import { offlineModeStore } from "@/store/offlineMode";
import { updatesStore } from "@/store/Updates";
export default defineComponent({
    name: "StatusPanel",
    methods: { useSettingsStore, useUserStore, updatesStore },
    components: { ButtonBase },
    props: {},
    setup(props, { emit }) {
        const userStore = useUserStore();
        const { t: translate } = useI18n();
        const time = ref("");
        const obId = ref("Unknown");
        const websocketStatus = ref("Unknown");
        const websocketSubscription = ref("Unknown");
        const dataHydrationStatus = ref("Unknown");
        const dataHydrationProgress = computed(() => dataHydration.status);
        const internetStatus = ref("Unknown");
        const internalIp = ref("Unknown");
        const mode = ref("Browser: online");
        const riceCookerVersion = ref("Not enabled");
        const api = ref(axios.defaults.baseURL);
        const uiVersion = devGetFrontendVersion();
        const unsyncedOfflineOrders = ref("0 (OK)");
        const update = () => {
            const masterSlave = useMasterSlave();
            api.value = axios.defaults.baseURL;
            time.value = new Date().toLocaleString();
            obId.value =
                masterSlave.obId +
                    " (" +
                    (masterSlave.isMaster.value ? "Master" : "Slave") +
                    ")";
            if (RiceCooker.isPresent()) {
                ;
                (async () => {
                    mode.value = "RC: " + (await window.riceCooker.getMode());
                    riceCookerVersion.value =
                        (await devGetRiceCookerVersion()) || "Unknown";
                    internalIp.value = await window.riceCooker.getIpAddress();
                })();
            }
            websocketStatus.value = websocket.getConnectionState();
            websocketSubscription.value = websocket.subscribed
                ? "Subscribed"
                : "Not subscribed!";
            dataHydrationStatus.value = dataHydration.isActive()
                ? "Active (" +
                    (Math.floor(new Date().getTime() / 1000) -
                        dataHydration.lastActivity) +
                    "s)"
                : "Inactive";
            internetStatus.value = navigator.onLine
                ? "Connected"
                : "Disconnected";
            if (RiceCooker.isPresent()) {
                RiceCooker.syncFetch()
                    .then((data) => {
                    unsyncedOfflineOrders.value =
                        (data.max > 0 ? "warning" : "ok") +
                            " (" +
                            data.max +
                            ")";
                })
                    .catch();
            }
        };
        const interval = setInterval(update, 500);
        update();
        onUnmounted(() => {
            clearInterval(interval);
        });
        const router = useRouter();
        const goToDebug = () => {
            emit("closeStatusPanel");
            router.push("/debug");
        };
        const toggleMasterSlave = async () => {
            useUserStore().togglingMasterSlave = true;
            const isMaster = useMasterSlave().isMaster.value;
            if (!isMaster && useSettingsStore().settings.master_ob_id) {
                useUserStore().confirm_overwrite_master = true;
            }
            else {
                try {
                    await useMasterSlave().setMaster(!isMaster);
                    await dataHydration.hydrateModule("settings", true);
                }
                catch (e) {
                    Sentry.captureException(e);
                }
                finally {
                    useUserStore().togglingMasterSlave = false;
                }
            }
        };
        const toggleOnlineOffline = async () => {
            if (!offlineModeStore().isOffline) {
                await offlineModeStore().setOffline();
            }
            else {
                await offlineModeStore().setOnline();
            }
        };
        const showImpersonationButton = process.env.VUE_APP_ENV !== "production";
        const adminImpersonation = () => {
            userStore.admin_info_modal_open = false;
            axios.post("/utils/development/impersonation/toggle").then(() => {
                userStore.fetchUser();
            });
        };
        const hasVoip = useSettingsStore().settings.voip_provider;
        const { subscribeToCallerIdEvents, VoipStore } = useCallerId();
        return {
            userStore,
            translate,
            obId,
            time,
            api,
            mode,
            uiVersion,
            RiceCooker,
            riceCookerVersion,
            websocketStatus,
            websocketSubscription,
            dataHydrationStatus,
            internetStatus,
            unsyncedOfflineOrders,
            internalIp,
            toggleMasterSlave,
            toggleOnlineOffline,
            adminImpersonation,
            showImpersonationButton,
            goToDebug,
            dataHydration,
            dataHydrationProgress,
            isHydrating: dataHydration.isHydratingAllModules,
            hasVoip,
            subscribeToCallerIdEvents,
            callerIdActive: VoipStore.subscribed,
        };
    },
    emits: ["closeStatusPanel"],
});
