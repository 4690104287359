import { computed, defineComponent, provide } from "vue";
export default defineComponent({
    name: "FormErrorsProvider",
    props: {
        errors: {
            type: Object,
            default: () => { },
        },
    },
    setup(props) {
        provide("errors", computed(() => props.errors));
    },
});
