import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ButtonBase = _resolveComponent("ButtonBase");
    const _component_RouteModal = _resolveComponent("RouteModal");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        (_ctx.active)
            ? (_openBlock(), _createBlock(_component_ButtonBase, {
                key: 0,
                variant: "outlined",
                name: "map",
                onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.showMap = !_ctx.showMap)),
                icon: "map",
                "data-testid": "map-modal",
                disabled: _ctx.disabled
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate("map")), 1)
                ]),
                _: 1
            }, 8, ["disabled"]))
            : _createCommentVNode("", true),
        _createVNode(_component_RouteModal, {
            show: _ctx.showMap,
            onClose: _cache[1] || (_cache[1] = ($event) => (_ctx.showMap = false))
        }, null, 8, ["show"])
    ], 64));
}
