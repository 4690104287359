import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "footer-items" };
const _hoisted_2 = { class: "menu-list" };
const _hoisted_3 = {
    key: 0,
    class: "statistic"
};
const _hoisted_4 = { class: "text" };
const _hoisted_5 = { class: "child-text" };
const _hoisted_6 = { class: "menu-list-right" };
const _hoisted_7 = { class: "messages-label" };
const _hoisted_8 = { class: "logout-text" };
const _hoisted_9 = { class: "language-selector" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ButtonBase = _resolveComponent("ButtonBase");
    const _component_ButtonMap = _resolveComponent("ButtonMap");
    const _component_ButtonDeliverers = _resolveComponent("ButtonDeliverers");
    const _component_ButtonBrake = _resolveComponent("ButtonBrake");
    const _component_router_link = _resolveComponent("router-link");
    const _component_Accordion = _resolveComponent("Accordion");
    const _component_IconBase = _resolveComponent("IconBase");
    const _component_MessageModal = _resolveComponent("MessageModal");
    const _component_LanguagePicker = _resolveComponent("LanguagePicker");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ButtonBase, {
                onClick: _ctx.goToProducts,
                variant: "outlined",
                icon: "food",
                type: "primary"
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate("products")), 1)
                ]),
                _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_ButtonMap, { type: "primary" }),
            _createVNode(_component_ButtonDeliverers, {
                showName: true,
                type: "primary"
            }),
            _createVNode(_component_ButtonBrake, { type: "primary" })
        ]),
        _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (menuItem) => {
                return (_openBlock(), _createElementBlock("div", {
                    key: menuItem.text
                }, [
                    (!menuItem.hasChildren)
                        ? (_openBlock(), _createBlock(_component_router_link, {
                            key: 0,
                            to: { name: menuItem.name },
                            onClick: ($event) => (_ctx.onClick(menuItem.name)),
                            class: _normalizeClass(["menu-item", [
                                    menuItem.name,
                                    { current: menuItem.name === _ctx.currentRoute },
                                ]])
                        }, {
                            default: _withCtx(() => [
                                (menuItem.name === 'archive' && _ctx.futureOrderCount > 0)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.futureOrderCountLabel), 1))
                                    : _createCommentVNode("", true),
                                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.translate(menuItem.text)), 1)
                            ]),
                            _: 2
                        }, 1032, ["to", "onClick", "class"]))
                        : (_openBlock(), _createBlock(_component_Accordion, { key: 1 }, {
                            title: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate(menuItem.text)), 1)
                            ]),
                            content: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuItem.children, (child) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                        key: child.name
                                    }, [
                                        _createVNode(_component_router_link, {
                                            to: { name: child.name },
                                            onClick: ($event) => (_ctx.onClick(child.name)),
                                            class: "children"
                                        }, {
                                            default: _withCtx(() => [
                                                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.translate(child.text)), 1)
                                            ]),
                                            _: 2
                                        }, 1032, ["to", "onClick"])
                                    ]));
                                }), 128))
                            ]),
                            _: 2
                        }, 1024))
                ]));
            }), 128))
        ]),
        _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", {
                class: "messages cursor-pointer",
                onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.obMessages.showModal = true))
            }, [
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.translate("message_box")), 1),
                (_ctx.obMessages.unreadMessageIds.length)
                    ? (_openBlock(), _createBlock(_component_IconBase, {
                        key: 0,
                        icon: "circle-filled",
                        class: "unread-messages animate-pulse-object"
                    }))
                    : _createCommentVNode("", true)
            ])
        ]),
        (_ctx.obMessages.showModal)
            ? (_openBlock(), _createBlock(_component_MessageModal, {
                key: 0,
                onClose: _cache[1] || (_cache[1] = ($event) => (_ctx.obMessages.showModal = false))
            }))
            : _createCommentVNode("", true),
        _createElementVNode("div", {
            class: "logout-div",
            onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.goToLogout()))
        }, [
            _createVNode(_component_IconBase, {
                class: "logout-icon",
                icon: 'unlock'
            }),
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.translate("logout")), 1)
        ]),
        _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_LanguagePicker, {
                icon: "web",
                transparent: false,
                "hide-selected": ""
            })
        ])
    ], 64));
}
