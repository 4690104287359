import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
export default defineComponent({
    name: "RemarksTab",
    props: {
        order: {
            type: Object,
            required: true,
        },
        scrollAreaRef: Function,
    },
    setup(props) {
        const { t: translate } = useI18n();
        const remarksContent = computed(() => props.order.remarks
            ? props.order.remarks
            : translate("there_are_no_remarks"));
        return {
            remarksContent,
        };
    },
});
