import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "delivery-details" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_IconBase = _resolveComponent("IconBase");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.deliveryType.icon)
            ? (_openBlock(), _createBlock(_component_IconBase, {
                key: 0,
                icon: _ctx.deliveryType.icon,
                class: "delivery-icon"
            }, null, 8, ["icon"]))
            : _createCommentVNode("", true),
        _createElementVNode("div", null, _toDisplayString(_ctx.translate(_ctx.deliveryType.label)), 1)
    ]));
}
