import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
export default defineComponent({
    name: "SliderTooltip",
    props: {
        value: Number,
        disabled: Boolean,
    },
    setup(props, context) {
        const { t: translate } = useI18n();
        const minus = () => {
            if (!props.disabled) {
                context.emit("minus");
            }
        };
        const plus = () => {
            if (!props.disabled) {
                context.emit("plus");
            }
        };
        return {
            translate,
            minus,
            plus,
        };
    },
    emits: ["minus", "plus"],
});
