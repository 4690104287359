import { defineComponent } from "vue";
import ScrollButtons from "@/ui-elements/layout/footer/ScrollButtons.vue";
import PaginationComponent from "@/ui-elements/layout/footer/PaginationComponent.vue";
import { useI18n } from "vue-i18n";
export default defineComponent({
    name: "PageFooter",
    components: {
        ScrollButtons,
        PaginationComponent,
    },
    props: {
        scrollArea: {
            type: Object,
        },
        pagination: {
            type: Object,
            default: () => { },
        },
        showPagination: {
            type: Boolean,
            default: true,
        },
        showTotalCount: {
            type: Boolean,
            default: true,
        },
        ready: Boolean,
        hidePerPage: Boolean,
    },
    setup() {
        const { t: translate } = useI18n();
        return {
            translate,
        };
    },
    emits: ["update:pagination"],
});
