import { defineComponent } from "vue";
import IconBase from "@/ui-elements/IconBase.vue";
export default defineComponent({
    name: "TabOptions",
    components: {
        IconBase,
    },
    props: {
        options: {
            type: Array,
            required: true,
        },
        value: String,
        disabled: {
            type: Boolean,
            default: false,
        },
        unselectable: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const updateValue = (clickedOption) => {
            if (props.unselectable && props.value === clickedOption.value) {
                return "";
            }
            return clickedOption.value;
        };
        return {
            updateValue,
        };
    },
    emits: ["update:value"],
});
