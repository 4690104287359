import { computed, defineComponent, ref, watch } from "vue";
import TextField from "@/ui-elements/input/text-field/TextField.vue";
import SearchResults from "@/pages/pos/modal/customer/search/SearchResults.vue";
import { useCustomerModalStore } from "@/store/CustomerModal";
import { calculateDirection, calculateDisplay, useFocus, } from "@/pages/pos/modal/customer/data/useSearchResults";
import { useCartStore } from "@/store/cart/Cart";
import { useDebounce } from "@/utils/useDebounce";
export default defineComponent({
    name: "SearchableTextField",
    components: { SearchResults, TextField },
    props: {
        label: String,
        customerProperty: {
            type: String,
            required: true,
        },
        boundingElement: {
            type: HTMLElement,
        },
        error: Boolean,
        message: String,
        name: {
            type: [Array, String],
            default: () => [],
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    setup: function (props) {
        const customerStore = useCustomerModalStore();
        const cartStore = useCartStore();
        const focussed = ref(false);
        const showResults = computed(() => calculateDisplay(focussed.value, cartStore.customer.address, props.customerProperty, customerStore.results));
        const loading = ref(false);
        watch([
            () => cartStore.customer.address[props.customerProperty],
            focussed,
        ], useDebounce(() => {
            if (focussed.value &&
                cartStore.customer.address[props.customerProperty]) {
                loading.value = true;
                customerStore
                    .fetchResults(cartStore.customer.address[props.customerProperty] || "", props.customerProperty)
                    .finally(() => (loading.value = false));
            }
        }, 300));
        const inputField = ref();
        const upOrDown = computed(() => calculateDirection(props.boundingElement, inputField.value?.$el));
        const parent = useFocus(focussed);
        return {
            cartStore,
            focussed,
            showResults,
            inputField,
            upOrDown,
            parent,
            loading,
        };
    },
});
