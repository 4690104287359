import { nextTick, onMounted } from "vue";
import TWEEN from "@tweenjs/tween.js";
const selectEasing = (_) => {
    switch (_) {
        case "CubicOut":
            return TWEEN.Easing.Cubic.Out;
        default:
            return TWEEN.Easing.Linear.None;
    }
};
export function tweenOnMount(tweenRef, duration, delay, easing) {
    onMounted(() => {
        nextTick(() => {
            function animate() {
                if (TWEEN.update()) {
                    requestAnimationFrame(animate);
                }
            }
            new TWEEN.Tween(tweenRef.value)
                .to({ t: 1.0 }, duration)
                .delay(delay)
                .start()
                .easing(selectEasing(easing));
            animate();
        });
    });
}
