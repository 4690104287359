import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "info" };
const _hoisted_2 = { class: "text" };
const _hoisted_3 = {
    key: 0,
    class: "from"
};
const _hoisted_4 = { class: "donut" };
const _hoisted_5 = ["transform"];
const _hoisted_6 = ["d"];
const _hoisted_7 = ["transform"];
const _hoisted_8 = ["d"];
const _hoisted_9 = {
    key: 0,
    class: "number"
};
const _hoisted_10 = {
    key: 1,
    class: "number"
};
const _hoisted_11 = {
    key: 2,
    class: "change"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SVGDefs = _resolveComponent("SVGDefs");
    const _component_LoadingIndicator = _resolveComponent("LoadingIndicator");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass([_ctx.data.name + '-display', "donut-display"])
    }, [
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.data.text), 1),
            (_ctx.data.from)
                ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.data.from), 1))
                : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_4, [
            (_openBlock(), _createElementBlock("svg", {
                class: _normalizeClass(["donut-chart", [_ctx.data.increasing ? 'up' : 'down']]),
                viewBox: "0 0 100 100"
            }, [
                _createVNode(_component_SVGDefs),
                _createElementVNode("g", {
                    class: "donut-circle",
                    transform: 'translate(' + _ctx.radius + ',' + _ctx.radius + ')'
                }, [
                    _createElementVNode("path", { d: _ctx.circle }, null, 8, _hoisted_6)
                ], 8, _hoisted_5),
                _createElementVNode("g", {
                    class: "donut-arc",
                    transform: 'translate(' + _ctx.radius + ',' + _ctx.radius + ')'
                }, [
                    _createElementVNode("path", { d: _ctx.arc }, null, 8, _hoisted_8)
                ], 8, _hoisted_7)
            ], 2)),
            _createElementVNode("div", {
                class: _normalizeClass(["numbers", [_ctx.data.increasing ? 'up' : 'down']])
            }, [
                (!_ctx.isLoading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.formatNumber(_ctx.data.number)), 1))
                    : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _createVNode(_component_LoadingIndicator)
                    ])),
                (_ctx.data.change)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.formatNumber(_ctx.data.change)), 1))
                    : _createCommentVNode("", true)
            ], 2)
        ])
    ], 2));
}
