import { computed, defineComponent, ref } from "vue";
import IconBase from "@/ui-elements/IconBase.vue";
import { mainMenuStructure } from "@/pageStructure/mainMenuStructure";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useClickAway } from "@/utils/useClickAway";
import { useAPIStore } from "@/store/API";
import MessageModal from "@/ui-elements/message-box/MessageModal.vue";
import { usePosMenusStore } from "@/store/PosMenus";
import { useCartStore } from "@/store/cart/Cart";
import { usePOSStore } from "@/store/POS";
import { dataHydration } from "@/services/DataHydrationService";
import { useOBMessagesStore } from "@/store/OBMessage";
import LanguagePicker from "@/ui-elements/LanguagePicker.vue";
import { useUserStore } from "@/store/User";
import { useOrdersStore } from "@/store/Orders";
import ButtonBase from "@/ui-elements/button-base/ButtonBase.vue";
import ButtonMap from "@/pages/orders/footer/ButtonMap.vue";
import ButtonDeliverers from "@/pages/orders/footer/ButtonDeliverers.vue";
import ButtonBrake from "@/pages/orders/footer/ButtonBrake.vue";
import Accordion from "@/ui-elements/Accordion.vue";
import { offlineModeStore } from "@/store/offlineMode";
export default defineComponent({
    name: "MobileMainMenu",
    components: {
        IconBase,
        MessageModal,
        LanguagePicker,
        ButtonBase,
        ButtonMap,
        ButtonDeliverers,
        ButtonBrake,
        Accordion,
    },
    setup() {
        const { t: translate } = useI18n();
        const router = useRouter();
        const route = useRoute();
        const apiStore = useAPIStore();
        const userStore = useUserStore();
        const obMessages = useOBMessagesStore();
        const orderStore = useOrdersStore();
        const futureOrderCount = computed(() => orderStore.futureOrderCount);
        // limit displayed label to 99. If over 99, show 99+
        const futureOrderCountLabel = computed(() => orderStore.futureOrderCount <= 99
            ? orderStore.futureOrderCount
            : "99+");
        const menuItems = computed(() => mainMenuStructure.filter((item) => {
            const archiveMode = !!Number(userStore.user.is_read_only);
            const addPos = !(item.name === "pos" && archiveMode);
            const addOrders = !(item.name === "orders" && archiveMode);
            return addPos && addOrders;
        }));
        const dropdownOpen = ref(false);
        const dropdown_ref = ref();
        const currentRoute = computed(() => router.currentRoute.value.meta.name);
        const showFlyerImage = computed(() => router.currentRoute.value.name === "flyer");
        useClickAway(dropdown_ref, () => (dropdownOpen.value = false));
        const posMenusStore = usePosMenusStore();
        const cartStore = useCartStore();
        const posStore = usePOSStore();
        const onClick = (routeName) => {
            // reset the pos page when click on it the second time
            if (routeName === "pos" && route.name === "pos") {
                posStore.posBack();
                posMenusStore.resetSelection();
                posMenusStore.resetSelectedProduct();
                cartStore.clearCurrentCart();
            }
            // forces orders to load when clicking on order again
            if (routeName === "orders" && route.name === "orders") {
                dataHydration.hydrateModule("orders", true);
            }
        };
        const goToProducts = () => router.push({ name: "menu-menu-products" });
        const goToLogout = () => router.push({ name: "logout" });
        return {
            isOffline: offlineModeStore().isOffline,
            currentRoute,
            translate,
            dropdownOpen,
            apiStore,
            showFlyerImage,
            obMessages,
            futureOrderCount,
            futureOrderCountLabel,
            open,
            onClick,
            menuItems,
            goToProducts,
            goToLogout,
        };
    },
});
