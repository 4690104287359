import { computed, defineComponent, ref } from "vue";
import IconBase from "../IconBase.vue";
import { useSettingsStore } from "@/store/Settings";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import ButtonBase from "@/ui-elements/button-base/ButtonBase.vue";
import Accordion from "@/ui-elements/Accordion.vue";
import DeviceCheck from "@/utils/responsive/DeviceCheck.vue";
import UserData from "@/ui-elements/page-header/UserData.vue";
export default defineComponent({
    name: "SettingsMenu",
    components: {
        UserData,
        IconBase,
        ButtonBase,
        Accordion,
        DeviceCheck,
    },
    setup() {
        const { t: translate } = useI18n();
        const router = useRouter();
        const currentRoute = computed(() => router.currentRoute.value.meta.name);
        const settingsStore = useSettingsStore();
        const menuItems = computed(() => {
            const items = Object.keys(settingsStore.settingsMenu).map((key) => settingsStore.settingsMenu[key]);
            return [
                {
                    text: "overview",
                    name: "settings/overview",
                    icon: "home",
                },
                ...items,
            ];
        });
        const back = () => router.push({ name: "orders" });
        const expandedMenuItemName = ref("");
        const toggleChildren = (menuItemName) => {
            if (expandedMenuItemName.value === menuItemName) {
                expandedMenuItemName.value = "";
            }
            else {
                expandedMenuItemName.value = menuItemName;
            }
        };
        const expandedMenuItemChildName = ref("");
        const toggleChildrenChild = (menuItemName) => {
            if (expandedMenuItemChildName.value === menuItemName) {
                expandedMenuItemChildName.value = "";
            }
            else {
                expandedMenuItemChildName.value = menuItemName;
            }
        };
        return {
            menuItems,
            translate,
            currentRoute,
            back,
            toggleChildren,
            expandedMenuItemName,
            toggleChildrenChild,
            expandedMenuItemChildName,
        };
    },
});
