import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import PopUpModal from "@/ui-elements/layout/modals/PopUpModal.vue";
import CheckSwitch from "@/ui-elements/input/check-switch/CheckSwitch.vue";
import TimeAdjust from "@/pages/orders/modals/time/TimeAdjust.vue";
export default defineComponent({
    name: "TimeModal",
    components: { CheckSwitch, PopUpModal, TimeAdjust },
    props: {
        show: Boolean,
        orderTime: String,
        smsDisabled: Boolean,
        emailDisabled: Boolean,
    },
    setup(props, context) {
        const { t: translate } = useI18n();
        const notifications = ref([]);
        const time = ref(props.orderTime);
        const changeNotifications = (type) => {
            const index = notifications.value.indexOf(type);
            if (index >= 0) {
                notifications.value.splice(index, 1);
            }
            else {
                notifications.value.push(type);
            }
        };
        const save = () => {
            context.emit("update:time", {
                time_set: time.value,
                notifications: notifications.value,
            });
            context.emit("close");
        };
        return {
            translate,
            notifications,
            time,
            changeNotifications,
            save,
        };
    },
    emits: ["close", "update:time"],
});
