import { computed, defineComponent } from "vue";
import IconBase from "@/ui-elements/IconBase.vue";
import { useDelivererStore } from "@/store/Deliverer";
export default defineComponent({
    name: "StatusIcon",
    components: {
        IconBase,
    },
    props: {
        orderStatus: String,
        delivererId: Number,
    },
    setup(props) {
        const delivererStore = useDelivererStore();
        const orderStatusIcon = computed(() => props.delivererId ? "circle-half-filled" : "circle-open");
        const delivererColor = computed(() => {
            if (props.delivererId) {
                const orderDeliverer = delivererStore.getDeliverer(props.delivererId);
                return orderDeliverer ? orderDeliverer.orders_color : "";
            }
            else {
                return "";
            }
        });
        return { orderStatusIcon, delivererColor };
    },
});
