import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "delivery col-span-2 justify-self-stretch" };
const _hoisted_2 = { class: "street-name" };
const _hoisted_3 = { class: "delivery-info" };
const _hoisted_4 = {
    key: 0,
    class: "distance-details"
};
const _hoisted_5 = { key: 0 };
const _hoisted_6 = { key: 1 };
const _hoisted_7 = { class: "payment-details justify-self-end" };
const _hoisted_8 = { class: "grid cols-1 gap-4 items-center" };
const _hoisted_9 = {
    key: 0,
    class: "reference-number text-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DeliveryDetails = _resolveComponent("DeliveryDetails");
    const _component_IconBase = _resolveComponent("IconBase");
    const _component_OrderPrice = _resolveComponent("OrderPrice");
    return (!_ctx.showButtons)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["order-details grid grid-cols-3", { 'divider-margin': _ctx.showRemarks || _ctx.showDeliverer }])
        }, [
            _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.order.label), 1),
                _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_DeliveryDetails, { "delivery-type": _ctx.deliveryType }, null, 8, ["delivery-type"]),
                    (_ctx.order.delivery === 'deliver')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                            _createVNode(_component_IconBase, {
                                icon: "circle-filled",
                                class: "circle-icon"
                            }),
                            (_ctx.showDuration)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.convertSecondsToHMS(_ctx.order.duration) + " min"), 1))
                                : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.order.distance + " km"), 1))
                        ]))
                        : _createCommentVNode("", true)
                ])
            ]),
            _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                    ((_ctx.showRefNr && _ctx.order.refnr) || _ctx.isForMap)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.order.refnr || _ctx.orderLetter), 1))
                        : _createCommentVNode("", true),
                    _createVNode(_component_OrderPrice, { order: _ctx.order }, null, 8, ["order"])
                ])
            ])
        ], 2))
        : _createCommentVNode("", true);
}
