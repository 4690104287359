import { defineComponent } from "vue";
import IconBase from "@/ui-elements/IconBase.vue";
export default defineComponent({
    name: "PageTitle",
    components: {
        IconBase,
    },
    props: {
        text: String,
        titleIcon: {
            type: String,
        },
        titleIconClass: {
            type: String,
        },
    },
});
