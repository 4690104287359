import { computed, defineComponent, ref } from "vue";
import RouteModal from "@/pages/orders/modals/map/OrdersMapModal.vue";
import { ButtonBase } from "@/ui-elements";
import { useI18n } from "vue-i18n";
import { useSettingsStore } from "@/store/Settings";
export default defineComponent({
    name: "ButtonMap",
    components: { ButtonBase, RouteModal },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const { t: translate } = useI18n();
        const showMap = ref(false);
        const active = computed(() => useSettingsStore().settings.orders_map === "1");
        return {
            translate,
            active,
            showMap,
        };
    },
});
