import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_TextField = _resolveComponent("TextField");
    return (_openBlock(), _createBlock(_component_TextField, {
        label: _ctx.translate('discount_code'),
        text: _ctx.discount,
        "onUpdate:text": _cache[0] || (_cache[0] = ($event) => ((_ctx.discount) = $event)),
        disabled: _ctx.disabled,
        error: !!_ctx.errorMessage,
        message: _ctx.errorMessage,
        icon: _ctx.discountValid ? 'check' : ''
    }, null, 8, ["label", "text", "disabled", "error", "message", "icon"]));
}
