import { computed, defineComponent, ref } from "vue";
import ButtonBase from "@/ui-elements/button-base/ButtonBase.vue";
import { useI18n } from "vue-i18n";
import { useBrakeStore } from "@/store/Brake";
import { useSettingsStore } from "@/store/Settings";
export default defineComponent({
    name: "OrderBrake",
    components: { ButtonBase },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const { t: translate } = useI18n();
        const brakeStore = useBrakeStore();
        const settingsStore = useSettingsStore();
        const obOrdersOnly = ref(false); // todo: from settings
        const buttonActive = computed(() => !obOrdersOnly.value);
        const action = (event) => {
            brakeStore.showModal();
        };
        const brakeSettingIsActive = (setting) => {
            // @ts-ignore
            return !!Number(settingsStore.settings[setting] ?? 0);
        };
        const brakeActive = computed(() => brakeSettingIsActive("closed_tmp") ||
            brakeSettingIsActive("pickup_closed_tmp") ||
            brakeSettingIsActive("pickup_min_time_tmp") ||
            brakeSettingIsActive("delivery_closed_tmp") ||
            brakeSettingIsActive("delivery_min_time_tmp"));
        return {
            brakeActive,
            buttonActive,
            action,
            translate,
            brakeStore,
            settingsStore,
        };
    },
});
