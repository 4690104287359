import { defineComponent, ref } from "vue";
import RoutePlannerMap from "@/pages/orders/modals/order/tabs/RoutePlannerMap.vue";
import { useUserStore } from "@/store/User";
import TabOptions from "@/ui-elements/input/TabOptions.vue";
import { multiLocationStore } from "@/store/MultiLocation";
export default defineComponent({
    name: "RoutePlannerTab",
    components: {
        RoutePlannerMap,
        TabOptions,
    },
    props: {
        order: {
            type: Object,
            required: true,
        },
        scrollAreaRef: Function,
    },
    setup() {
        const mode = ref("bicycling");
        const userStore = useUserStore();
        const mapModes = ref([
            {
                value: "bicycling",
                icon: "scooter",
            },
            {
                value: "driving",
                icon: "car",
            },
            {
                value: "walking",
                icon: "walking",
            },
        ]);
        const routingMessage = ref("");
        return {
            mode,
            userStore,
            mapModes,
            routingMessage,
            multiLocation: multiLocationStore(),
        };
    },
});
