import { ORDER_DELIVERIES, ORDER_STATUSES_FOR_LABELS, } from "@/interface/orders/Order";
import { PaymentMethod } from "@/pages/pos/ticket/PaymentMethod";
import { useI18n } from "vue-i18n";
import { useUtilsStore } from "@/store/Utils";
import { PAYMENT_STATUSES } from "@/interface/orders/Payment";
import { computed } from "vue";
import { useDelivererStore } from "@/store/Deliverer";
import { isMethodShown } from "@/pages/orders/utils/usePaymentMethods";
import { useFormatCurrency } from "@/utils/useCurrencySymbol";
import { useSettingsStore } from "@/store/Settings";
export const useOrderOptions = (order, initialPaymentMethod) => {
    const delivererStore = useDelivererStore();
    const utilsStore = useUtilsStore();
    const { t: translate } = useI18n();
    const statusOptions = ORDER_STATUSES_FOR_LABELS.map((status) => {
        return {
            label: translate("var_order.status." + status),
            value: status,
        };
    });
    const deliveryOptions = ORDER_DELIVERIES.map((delivery) => {
        return {
            label: translate("var_order.delivery." + delivery),
            value: delivery,
        };
    });
    const delivererOptions = computed(() => {
        if (order.value.delivery !== "deliver") {
            return [];
        }
        const options = delivererStore.deliverers.map((deliverer) => {
            return {
                value: deliverer.id,
                label: deliverer.first_name +
                    " " +
                    deliverer.last_name,
            };
        });
        return [{ value: null, label: "None" }, ...options];
    });
    const paymentStatusOptions = PAYMENT_STATUSES.map((status) => {
        return {
            label: translate("var_order.paymentStatus." + status),
            value: status,
        };
    });
    const paymentMethodOptions = computed(() => {
        return Object.keys(utilsStore.paymentMethods)
            .filter((methodKey) => isMethodShown(methodKey) ||
            methodKey === initialPaymentMethod)
            .map((methodKey) => {
            let label = utilsStore.paymentMethods[methodKey].name;
            let isDisabled = false;
            if (order.value.payment2.amount) {
                label =
                    utilsStore.paymentMethods[methodKey].name +
                        (order.value.payment2.method
                            ? " (" +
                                useFormatCurrency(order.value.total -
                                    order.value.payment2.amount) +
                                ")"
                            : "");
            }
            if (
            // OBV3-251: always allow pin with payment_pin_via_archive_allowed
            useSettingsStore().settings
                .payment_pin_via_archive_allowed === "0" &&
                methodKey === PaymentMethod.PIN.valueOf() &&
                !!Number(useSettingsStore().settings.adyen_pin_active) &&
                (useSettingsStore().settings.adyen_pin_poiid ||
                    localStorage.getItem("adyen_pin_poiid_local"))) {
                isDisabled = true;
            }
            return {
                label: label,
                value: methodKey,
                disabled: isDisabled,
            };
        });
    });
    return {
        statusOptions,
        deliveryOptions,
        delivererOptions,
        paymentStatusOptions,
        paymentMethodOptions,
    };
};
