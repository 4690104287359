import { defineComponent } from "vue";
import { getFileNameFormPath } from "@/ui-elements/file-browser/useFileBrowser";
import ImageOrVideo from "@/ui-elements/file-browser/ImageOrVideo.vue";
export default defineComponent({
    name: "ImageComponent",
    components: { ImageOrVideo },
    props: {
        src: {
            type: String,
            default: "",
        },
        selected: Boolean,
    },
    setup() {
        return {
            getFileNameFormPath,
        };
    },
    emit: ["select"],
});
