import { CustomerAddress } from "@/interface/Customers/CustomerAddress"
import { FormErrors } from "@/utils/useFormErrors"
import { computed, ComputedRef, Ref, ref, UnwrapRef, watch } from "vue"
import axios, { AxiosResponse, Canceler } from "axios"
import { useCartStore } from "@/store/cart/Cart"
import { useCustomerModalStore } from "@/store/CustomerModal"
import { dateAfterToday } from "@/pages/pos/modal/customer/data/useUtils"
import { useDebounce } from "@/utils/useDebounce"
import i18n from "@/i18n"
import { setToastNotification } from "@/ui-elements/toast-notification/setToastNotification"
import { offlineModeStore } from "@/store/offlineMode"
export const requiredFields = [
    "street",
    "street_number",
    "phone",
    "zipcode",
    "city",
] as Array<keyof CustomerAddress>
export const getInitialErrors = (): FormErrors => {
    return {
        validationErrors: {} as Record<keyof CustomerAddress, string[]>,
        processingError: "",
    }
}
export const isEmailValid = (email: string) => {
    return !!email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
}

type AddressErrors = {
    out_of_zip_area: boolean
    out_of_duration_range: boolean
    out_of_distance_range: boolean
    invalid_address: boolean
}

type AutoCompleteErrors = Record<string, Array<string>>
const defaultAddressErrors: AddressErrors = {
    out_of_zip_area: false,
    out_of_duration_range: false,
    out_of_distance_range: false,
    invalid_address: false,
} as const

const mapResultToError: Record<
    | "inDeliveryZipArea"
    | "inDeliveryDuration"
    | "inDeliveryDistance"
    | "invalidAddress",
    keyof AddressErrors
> = {
    inDeliveryZipArea: "out_of_zip_area",
    inDeliveryDuration: "out_of_duration_range",
    inDeliveryDistance: "out_of_distance_range",
    invalidAddress: "invalid_address",
}

export const addressKeys = ["zipcode", "street_number"]
const getValidationErrors = (addressErrors: Ref<AddressErrors>): FormErrors => {
    const { t: translate } = i18n.global
    const cartStore = useCartStore()
    const errors: FormErrors = getInitialErrors()

    if (
        cartStore.customer.address.email &&
        !isEmailValid(cartStore.customer.address.email)
    ) {
        errors.validationErrors.email = [translate("email_is_not_valid")]
    }
    if (
        dateAfterToday(cartStore.currentCart.cart.schedule.date) &&
        !cartStore.currentCart.cart.schedule.time
    ) {
        errors.validationErrors.time = [translate("field_is_required")]
    }

    if (cartStore.currentCart.cart.type !== "deliver") {
        return errors
    }
    requiredFields.forEach((key) => {
        if (!cartStore.customer.address[key]) {
            errors.validationErrors[key] = [translate("field_is_required")]
        }
    })

    Object.keys(defaultAddressErrors).forEach((key: string): void => {
        if (addressErrors.value[key as keyof AddressErrors]) {
            if (!errors.validationErrors["zipcode"]) {
                errors.validationErrors["zipcode"] = [translate(key)]
            } else {
                errors.validationErrors["zipcode"].push(translate(key))
            }
        }
    })

    return errors
}

export const useCustomerValidation = () => {
    const cartStore = useCartStore()
    const modalStore = useCustomerModalStore()
    const addressErrors: Ref<UnwrapRef<AddressErrors>> =
        ref(defaultAddressErrors)
    const phoneError: Ref<string> = ref("")
    const autoCompleteErrors: Ref<AutoCompleteErrors> = ref({})
    const formErrors: ComputedRef<FormErrors> = computed(() => {
        if (!modalStore.doValidation) {
            return getInitialErrors()
        }
        return getValidationErrors(addressErrors)
    })
    const isAutoCompletingAddress = ref(false)
    let cancelRequest: Canceler | undefined
    const autocompleteAddress = async (): Promise<void> => {
        isAutoCompletingAddress.value = true

        if (offlineModeStore().isOffline) {
            isAutoCompletingAddress.value = false
            return
        }

        if (cancelRequest) {
            cancelRequest()
        }

        autoCompleteErrors.value = {}

        axios
            .get("/utils/address/autocomplete", {
                params: {
                    streetnumber: cartStore.customer.address.street_number,
                    zipcode: cartStore.customer.address.zipcode,
                    country: cartStore.customer.address.country,
                    city: cartStore.customer.address.city,
                    street:
                        cartStore.customer.address.country.toUpperCase() ===
                        "nl"
                            ? null
                            : cartStore.customer.address.street,
                },
                cancelToken: new axios.CancelToken((c) => {
                    cancelRequest = c
                }),
            })
            .then((response: AxiosResponse<any>): void => {
                if (!response.data?.data?.address) {
                    return
                }

                Object.keys(mapResultToError).forEach((error: string): void => {
                    const typedError = error as keyof typeof mapResultToError

                    const mappedErrorKey: string =
                        mapResultToError[typedError] ?? null
                    if (!mappedErrorKey) return

                    addressErrors.value = {
                        ...addressErrors.value,
                        [mappedErrorKey]: false,
                    }

                    if (
                        response.data?.meta &&
                        typedError in response.data.meta &&
                        !Number(response.data?.meta[typedError])
                    ) {
                        addressErrors.value = {
                            ...addressErrors.value,
                            [mappedErrorKey]: true,
                        }
                    }
                })

                cartStore.customer.address = {
                    ...cartStore.customer.address,
                    street: response.data.data.address.streetname,
                    city: response.data.data.address.city,
                }
            })
            .catch((response): void => {
                if (!axios.isCancel(response)) {
                    if (response?.response?.status === 422) {
                        const data = response.response.data.data
                        if (data) {
                            Object.keys(data).forEach((field: string) => {
                                autoCompleteErrors.value[field] = data[field]
                            })
                        }
                    }

                    addressErrors.value.out_of_distance_range = false
                    addressErrors.value.out_of_duration_range = false
                    addressErrors.value.out_of_zip_area = false
                    addressErrors.value.invalid_address = true
                }
            })
            .finally((): void => {
                isAutoCompletingAddress.value = false
            })
    }
    watch(
        requiredFields
            .map((field) => () => cartStore.customer.address[field])
            .concat([
                () => cartStore.customer.address.email,
                () => cartStore.customer.address.phone,
                () => cartStore.currentCart.cart.schedule.date,
                () => cartStore.currentCart.cart.type,
            ]),
        (): void => {
            modalStore.doValidation = true
        }
    )
    watch(
        [
            () => cartStore.customer.address.zipcode,
            () => cartStore.customer.address.street_number,
            () => cartStore.customer.address.country,
            () => cartStore.customer.address.street,
        ],
        useDebounce((): void => {
            if (
                cartStore.customer.address.zipcode &&
                cartStore.customer.address.street_number &&
                (String(
                    cartStore.customer.address.country ?? "NL"
                ).toUpperCase() !== "NL"
                    ? cartStore.customer.address.street
                    : !cartStore.customer.address.street)
            ) {
                autocompleteAddress()
            }
        }, 500)
    )
    const showValidationNotification = (
        skipErrors: boolean = false
    ): boolean => {
        if (phoneError.value) {
            showInvalidPhone()
            return true
        }
        if (!modalStore.hasValidAddress) {
            showMissingDataNotification()
            return true
        }
        if (
            cartStore.customer.address.email &&
            !isEmailValid(cartStore.customer.address.email)
        ) {
            showInvalidEmail()
            return true
        }
        if (
            dateAfterToday(cartStore.currentCart.cart.schedule.date) &&
            !cartStore.currentCart.cart.schedule.time
        ) {
            showTimeRequired()
            return true
        }

        if (!skipErrors) {
            const { t: translate } = i18n.global
            const errorMessages: string[] = (
                Object.keys(defaultAddressErrors) as Array<keyof AddressErrors>
            )
                .filter(
                    (
                        key:
                            | "out_of_zip_area"
                            | "out_of_duration_range"
                            | "out_of_distance_range"
                            | "invalid_address"
                    ) => addressErrors.value[key]
                )
                .map((key) => translate(key))

            if (errorMessages.length) {
                showInvalidAddressNotification(errorMessages)

                return true
            }
        }

        return false
    }

    return {
        showValidationNotification,
        formErrors,
        phoneError,
        isAutoCompletingAddress,
        autoCompleteErrors,
    }
}
const showInvalidPhone = () => {
    const { t: translate } = i18n.global
    setToastNotification(
        translate("please_try_again"),
        translate("app_phoneNotValid"),
        "danger"
    )
}
const showInvalidEmail = () => {
    const { t: translate } = i18n.global
    setToastNotification(
        translate("please_try_again"),
        translate("email_is_not_valid"),
        "danger"
    )
}
const showTimeRequired = () => {
    const { t: translate } = i18n.global
    setToastNotification(
        translate("missing_data"),
        "Desired time is required!",
        "danger"
    )
}

const showInvalidAddressNotification = (messages: string[]): void => {
    const { t: translate } = i18n.global
    setToastNotification(
        translate("address_is_invalid") +
            (messages.length
                ? ", " +
                  translate(
                      messages.length === 1 ? "cause" : "causes"
                  ).toLowerCase() +
                  ":"
                : ""),
        messages.length ? messages.join("<br >") : "",
        "danger"
    )
}
const showMissingDataNotification = () => {
    const { t: translate } = i18n.global

    setToastNotification(
        translate("missing_data"),
        translate("components_brake_additional_field"),
        "danger"
    )
}
