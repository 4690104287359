import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_PopUpModal = _resolveComponent("PopUpModal");
    return (_openBlock(), _createBlock(_component_PopUpModal, {
        show: "",
        title: _ctx.translate('menu_close_modal_title'),
        onClose: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('close'))),
        onSubmit: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('confirm'))),
        class: "confirm-close",
        submitButtonText: _ctx.translate('close')
    }, {
        default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.translate("menu_close_modal_content")), 1)
        ]),
        _: 1
    }, 8, ["title", "submitButtonText"]));
}
