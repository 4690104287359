import { defineComponent } from "vue";
import IconBase from "@/ui-elements/IconBase.vue";
export default defineComponent({
    name: "LabelComponent",
    components: {
        IconBase,
    },
    props: {
        label: {
            type: String,
        },
        labelIcon: {
            type: String,
        },
        labelIconClass: {
            type: String,
        },
    },
});
