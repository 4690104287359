import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import ProductRow from "@/pages/orders/modals/order/tabs/ProductRow.vue";
import { useSettingsStore } from "@/store/Settings";
export default defineComponent({
    name: "OrderTab",
    components: {
        ProductRow,
    },
    props: {
        order: {
            type: Object,
            required: true,
        },
        scrollAreaRef: Function,
    },
    setup() {
        const { t: translate } = useI18n();
        const { settings } = useSettingsStore();
        return {
            translate,
            settings,
        };
    },
});
