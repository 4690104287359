import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_TabOptions = _resolveComponent("TabOptions");
    return (_openBlock(), _createBlock(_component_TabOptions, {
        class: "dispatch-options",
        options: _ctx.optionsArray,
        value: _ctx.store.currentCart.cart.type,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event) => ((_ctx.store.currentCart.cart.type) = $event)),
        unselectable: true
    }, null, 8, ["options", "value"]));
}
