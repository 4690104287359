import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "offline top-bar"
};
const _hoisted_2 = {
    key: 1,
    class: "top-bar offline"
};
const _hoisted_3 = { class: "text" };
const _hoisted_4 = {
    key: 2,
    class: "shop-background"
};
const _hoisted_5 = { class: "overlay" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_MenuLogo = _resolveComponent("MenuLogo");
    const _component_UserData = _resolveComponent("UserData");
    const _component_IconBase = _resolveComponent("IconBase");
    const _component_SettingsMenu = _resolveComponent("SettingsMenu");
    const _component_MobileMainMenu = _resolveComponent("MobileMainMenu");
    const _component_CallerId = _resolveComponent("CallerId");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", {
            class: _normalizeClass(["hamburger", { settingsHeader: _ctx.isSettings }])
        }, [
            _createVNode(_component_MenuLogo, {
                class: _normalizeClass({ settings: _ctx.isSettings })
            }, null, 8, ["class"]),
            _createVNode(_component_UserData),
            _createElementVNode("button", {
                class: "hamburger-menu-button",
                onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.showNav = !_ctx.showNav))
            }, " ☰ ")
        ], 2),
        (_ctx.showNav)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("div", {
                    class: _normalizeClass(["page-header", { settings: _ctx.isSettings, draggable: _ctx.RiceCookerPresent }])
                }, [
                    _createVNode(_component_IconBase, { icon: "icon-burger" }),
                    (_ctx.isSettings)
                        ? (_openBlock(), _createBlock(_component_SettingsMenu, { key: 0 }))
                        : (_openBlock(), _createBlock(_component_MobileMainMenu, { key: 1 }))
                ], 2),
                _createVNode(_component_CallerId),
                (Boolean(Number(_ctx.settings.tb_api_active)) &&
                    _ctx.settings.tb_api_version !== 'pos_api')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.translate("tb_external_order_api_deprecated_body")), 1))
                    : _createCommentVNode("", true),
                (_ctx.offlineModeStore().isOffline ||
                    _ctx.networkIssuesStore().hasNetworkIssues ||
                    _ctx.archive_mode)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createElementVNode("span", _hoisted_3, [
                            _createVNode(_component_IconBase, {
                                icon: "warning",
                                class: "mr-2"
                            }),
                            _createElementVNode("b", null, _toDisplayString(_ctx.translate("warning")) + ": ", 1),
                            (_ctx.offlineModeStore().isOffline)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _createTextVNode(_toDisplayString(_ctx.translate("app_offlineMode")), 1)
                                ], 64))
                                : (_ctx.networkIssuesStore().hasNetworkIssues)
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                        _createTextVNode(_toDisplayString(_ctx.translate("app_network_issues")), 1)
                                    ], 64))
                                    : (_ctx.archive_mode)
                                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                            _createTextVNode(_toDisplayString(_ctx.translate("warning_archive_mode")), 1)
                                        ], 64))
                                        : _createCommentVNode("", true)
                        ])
                    ]))
                    : _createCommentVNode("", true),
                (_ctx.showFlyerImage)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("h1", null, _toDisplayString(_ctx.translate("order_flyers")), 1)
                        ])
                    ]))
                    : _createCommentVNode("", true)
            ], 64))
            : _createCommentVNode("", true)
    ], 64));
}
