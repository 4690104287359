import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-57e0dc61"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["loop"];
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (Number(_ctx.settings.sound_play) && _ctx.settings.sound_file)
        ? (_openBlock(), _createElementBlock("audio", {
            key: 0,
            loop: !!Number(_ctx.settings.sound_loop) ||
                !_ctx.once ||
                (Number(_ctx.settings.print_auto) === 0 &&
                    _ctx.settings.print_when !== 'direct'),
            autoplay: ""
        }, [
            _createElementVNode("source", {
                src: '/assets/sounds/' + _ctx.settings.sound_file,
                type: "audio/mpeg"
            }, null, 8, _hoisted_2)
        ], 8, _hoisted_1))
        : _createCommentVNode("", true);
}
