import { computed, defineComponent, watch } from "vue";
import { useI18n } from "vue-i18n";
import TabOptions from "@/ui-elements/input/TabOptions.vue";
import { useCartStore } from "@/store/cart/Cart";
import { useSettingsStore } from "@/store/Settings";
import { DispatchType } from "@/interface/Cart";
import { getInitialDispatchType, } from "@/utils/settings/dispatchType";
export default defineComponent({
    name: "DispatchField",
    components: { TabOptions },
    setup() {
        const { t: translate } = useI18n();
        const store = useCartStore();
        const settingsStore = useSettingsStore();
        const defaultDispatchType = getInitialDispatchType();
        const dispatchConditions = {
            [DispatchType.Deliver]: defaultDispatchType !== DispatchType.PickUpOnly,
            [DispatchType.PickUp]: defaultDispatchType !== DispatchType.DeliverOnly,
            [DispatchType.EatIn]: settingsStore.settings.dinein === "1" ||
                settingsStore.settings.dinein_menu === "1",
        };
        const optionsArray = computed(() => [
            {
                value: DispatchType.Deliver,
                label: translate("delivery"),
            },
            {
                value: DispatchType.PickUp,
                label: translate("takeaway"),
            },
            {
                value: DispatchType.EatIn,
                label: translate("restaurant"),
            },
        ].filter((dispatchOption) => dispatchConditions[dispatchOption.value]));
        watch(() => store.currentCart.cart.type, () => store.updateCart());
        return {
            store,
            optionsArray,
            translate,
        };
    },
});
