import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "messagebox-header" };
const _hoisted_2 = { class: "modal-title" };
const _hoisted_3 = { class: "messagebox-content" };
const _hoisted_4 = { class: "list-content" };
const _hoisted_5 = {
    key: 0,
    class: "messages-list",
    ref: "scrollArea"
};
const _hoisted_6 = { key: 0 };
const _hoisted_7 = {
    key: 1,
    class: "no-messages"
};
const _hoisted_8 = { class: "message-preview" };
const _hoisted_9 = {
    key: 1,
    src: "/assets/images/mailbox.png",
    class: "mailbox-image"
};
const _hoisted_10 = {
    key: 2,
    class: "zero-unread-msgs"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ButtonBase = _resolveComponent("ButtonBase");
    const _component_TextField = _resolveComponent("TextField");
    const _component_LoadingIndicator = _resolveComponent("LoadingIndicator");
    const _component_MessageSingle = _resolveComponent("MessageSingle");
    const _component_HorizontalBar = _resolveComponent("HorizontalBar");
    const _component_IconBase = _resolveComponent("IconBase");
    const _component_PageFooter = _resolveComponent("PageFooter");
    const _component_MessageView = _resolveComponent("MessageView");
    const _component_ModalWindow = _resolveComponent("ModalWindow");
    return (_openBlock(), _createBlock(_component_ModalWindow, {
        show: "",
        onClose: _cache[3] || (_cache[3] = ($event) => (_ctx.$emit('close'))),
        class: _normalizeClass(["messagebox-modal",
            _ctx.messagesList[_ctx.selectedIndex]
                ? 'min-width-loaded'
                : 'min-width-loading'
        ])
    }, {
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_ButtonBase, {
                    icon: "delete",
                    class: "messagebox-close",
                    onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('close')))
                }),
                _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.translate("message_box")), 1),
                _createVNode(_component_TextField, {
                    class: "seach-input",
                    text: _ctx.filterOptions.search,
                    "onUpdate:text": _cache[1] || (_cache[1] = ($event) => ((_ctx.filterOptions.search) = $event)),
                    icon: "search",
                    placeholder: _ctx.translate('search') + '...'
                }, null, 8, ["text", "placeholder"])
            ]),
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                    (_ctx.messagesList)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            (_ctx.loading)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                    _createVNode(_component_LoadingIndicator, { cover: "" })
                                ]))
                                : _createCommentVNode("", true),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messagesList, (message, i) => {
                                return (_openBlock(), _createElementBlock("div", { key: i }, [
                                    _createVNode(_component_MessageSingle, {
                                        message: message,
                                        onClick: ($event) => (_ctx.selectedIndex = i),
                                        selected: _ctx.selectedIndex === i,
                                        "is-new": _ctx.obMessagesStore.unreadMessageIds.includes(message.id)
                                    }, null, 8, ["message", "onClick", "selected", "is-new"]),
                                    (i < _ctx.messagesList.length - 1)
                                        ? (_openBlock(), _createBlock(_component_HorizontalBar, { key: 0 }))
                                        : _createCommentVNode("", true)
                                ]));
                            }), 128))
                        ], 512))
                        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _createVNode(_component_IconBase, {
                                icon: "search-rows",
                                class: "no-rows"
                            }),
                            _createElementVNode("div", null, _toDisplayString(_ctx.translate("no_messages")), 1)
                        ])),
                    _createVNode(_component_PageFooter, {
                        scrollArea: _ctx.scrollArea,
                        pagination: _ctx.pagination,
                        "onUpdate:pagination": _cache[2] || (_cache[2] = ($event) => ((_ctx.pagination) = $event)),
                        "hide-per-page": ""
                    }, null, 8, ["scrollArea", "pagination"])
                ]),
                _createElementVNode("div", _hoisted_8, [
                    (_ctx.loading)
                        ? (_openBlock(), _createBlock(_component_LoadingIndicator, {
                            key: 0,
                            cover: ""
                        }))
                        : _createCommentVNode("", true),
                    (_ctx.selectedIndex < 0)
                        ? (_openBlock(), _createElementBlock("img", _hoisted_9))
                        : _createCommentVNode("", true),
                    (_ctx.selectedIndex < 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                            _createVNode(_component_IconBase, {
                                icon: "party-popper",
                                class: "party-popper"
                            }),
                            _createElementVNode("span", null, _toDisplayString(_ctx.translate("message_box_zero_unread_messages")), 1)
                        ]))
                        : _createCommentVNode("", true),
                    (_ctx.messagesList[_ctx.selectedIndex])
                        ? (_openBlock(), _createBlock(_component_MessageView, {
                            key: 3,
                            onToPreviousMessage: _ctx.showPreviousMessage,
                            onToNextMessage: _ctx.showNextMessage,
                            message: _ctx.messagesList[_ctx.selectedIndex],
                            disablePreviousButton: _ctx.disablePrevious,
                            disableNextButton: _ctx.disableNext
                        }, null, 8, ["onToPreviousMessage", "onToNextMessage", "message", "disablePreviousButton", "disableNextButton"]))
                        : _createCommentVNode("", true)
                ])
            ])
        ]),
        _: 1
    }, 8, ["class"]));
}
