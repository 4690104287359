import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "status-icon" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_IconBase = _resolveComponent("IconBase");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_IconBase, {
            icon: _ctx.orderStatusIcon,
            class: _normalizeClass(["status", [_ctx.orderStatus, { assigned: Boolean(_ctx.delivererId) }]]),
            style: _normalizeStyle({ color: _ctx.delivererColor })
        }, null, 8, ["icon", "class", "style"]),
        (_ctx.orderStatus === 'pending-ready')
            ? (_openBlock(), _createBlock(_component_IconBase, {
                key: 0,
                icon: "check",
                class: "check-icon"
            }))
            : _createCommentVNode("", true)
    ]));
}
