import { financialName, financialStructure, } from "@/pageStructure/FinancialStructure";
import { shopName, shopMenu } from "@/pageStructure/MarketingStructure";
import { settingsName } from "@/pageStructure/SettingsStructure";
import { customersMenu, customersName, } from "@/pageStructure/CustomersStructure";
import { archiveName } from "@/pageStructure/ArchiveStructure";
import { salesName, salesStructure, } from "@/pageStructure/SalesStatisticsStructure";
import { posName } from "@/pageStructure/POSStructure";
import { ordersName } from "@/pageStructure/OrdersStructure";
import { kitchenName } from "@/pageStructure/KitchenStructure";
export const mainMenuStructure = [
    {
        text: "pos",
        name: posName,
        icon: "pos",
        hasChildren: false,
        children: null,
    },
    {
        text: "orders",
        name: ordersName,
        icon: "food-bowl",
        hasChildren: false,
        children: null,
    },
    {
        text: "archive",
        name: archiveName,
        icon: "archive",
        hasChildren: false,
        children: null,
    },
    {
        text: "marketing",
        name: shopName,
        icon: "mega-phone",
        hasChildren: true,
        children: shopMenu,
    },
    {
        text: "customers",
        name: customersName,
        icon: "customers",
        hasChildren: true,
        children: customersMenu,
    },
    {
        text: "financial.title",
        name: financialName,
        icon: "euro-circle",
        hasChildren: true,
        children: financialStructure,
    },
    {
        text: "sales_statistics",
        name: salesName,
        icon: "sales-statistics",
        hasChildren: true,
        children: salesStructure,
    },
    {
        text: "settings",
        name: settingsName,
        icon: "cog",
        hasChildren: false,
        children: null,
    },
    {
        text: "number_buddy",
        name: "numberBuddy",
        icon: "text-number-list",
        hasChildren: false,
        children: null,
    },
    {
        text: "kitchen_screen",
        name: kitchenName,
        icon: "food",
        hasChildren: false,
        children: null,
    },
];
