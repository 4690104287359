import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import MailingField from "@/pages/pos/modal/customer/fields/MailingField.vue";
import NotesField from "@/pages/pos/modal/customer/fields/NotesField.vue";
import SearchableTextField from "@/pages/pos/modal/customer/fields/SearchableTextField.vue";
import PhoneField from "@/pages/pos/modal/customer/fields/PhoneField.vue";
import CountryField from "@/pages/pos/modal/customer/fields/CountryField.vue";
import { useCartStore } from "@/store/cart/Cart";
import SelectComponent from "@/ui-elements/input/select/SelectComponent.vue";
import { useCartDiscountsStore } from "@/store/CartDiscount";
import IconBase from "@/ui-elements/IconBase.vue";
import Tooltip from "@/ui-elements/Tooltip.vue";
export default defineComponent({
    name: "CustomerFields",
    components: {
        SelectComponent,
        SearchableTextField,
        NotesField,
        MailingField,
        PhoneField,
        CountryField,
        IconBase,
        Tooltip,
    },
    props: {
        isAutoCompletingAddress: {
            type: Boolean,
        },
        formErrors: {
            type: Object,
        },
    },
    setup() {
        const { t: translate } = useI18n();
        const cartStore = useCartStore();
        const discountsStore = useCartDiscountsStore();
        const boundingElement = ref();
        const isNL = computed(() => String(cartStore.customer.address.country ?? "NL").toUpperCase() === "NL");
        const discountCodes = computed(() => discountsStore.discounts.simple.map((discount) => ({
            value: discount.id,
            label: discount.full_label || discount.label,
        })));
        const canPayByInvoice = computed(() => cartStore.customer?.invoice?.enabled);
        return {
            translate,
            boundingElement,
            cartStore,
            discountCodes,
            canPayByInvoice,
            isNL,
        };
    },
    emits: ["phoneValidation"],
});
