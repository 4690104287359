import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "value" };
const _hoisted_2 = { class: "val" };
const _hoisted_3 = { class: "minutes" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", {
            class: "minus tooltip-button",
            onClick: _cache[0] || (_cache[0] = _withModifiers(
            //@ts-ignore
            (...args) => (_ctx.minus && _ctx.minus(...args)), ["stop"])),
            onMousedown: _cache[1] || (_cache[1] = _withModifiers(() => { }, ["stop"]))
        }, " – ", 32),
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, "+ " + _toDisplayString(_ctx.value), 1),
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate("components_brake_delay_minutes")), 1)
        ]),
        _createElementVNode("div", {
            class: "plus tooltip-button",
            onClick: _cache[2] || (_cache[2] = _withModifiers(
            //@ts-ignore
            (...args) => (_ctx.plus && _ctx.plus(...args)), ["stop"])),
            onMousedown: _cache[3] || (_cache[3] = _withModifiers(() => { }, ["stop"]))
        }, "+", 32)
    ], 64));
}
