import { defineComponent, ref } from "vue";
import { useClickAway } from "@/utils/useClickAway";
import StatusPanel from "@/ui-elements/page-header/StatusPanel.vue";
import { useRouter } from "vue-router";
import { useDoubleClick } from "@/utils/useDoubleClick";
import { useSplitPaymentStore } from "@/store/SplitPayment";
export default defineComponent({
    name: "MenuLogo",
    components: {
        StatusPanel,
    },
    setup() {
        const router = useRouter();
        const showStatusPanel = ref(false);
        const logo = ref();
        useClickAway(logo, () => {
            showStatusPanel.value = false;
        });
        const click = useDoubleClick(() => !useSplitPaymentStore().isPartiallyPaid
            ? router.push({ name: "orders" })
            : null, () => (showStatusPanel.value = !showStatusPanel.value), 250);
        return { showStatusPanel, click };
    },
});
