import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import TextField from "@/ui-elements/input/text-field/TextField.vue";
export default defineComponent({
    name: "BrowserHeader",
    components: { TextField },
    props: {
        number: Number,
        diskOptions: {
            type: Array,
            required: true,
        },
        disk: String,
        search: String,
    },
    setup(props) {
        const { t: translate } = useI18n();
        return {
            translate,
        };
    },
    emits: ["update:disk", "update:string"],
});
