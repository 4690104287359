import { defineComponent } from "vue";
import PopUpModal from "@/ui-elements/layout/modals/PopUpModal.vue";
import { useI18n } from "vue-i18n";
export default defineComponent({
    name: "CloseConfirmationModal",
    components: {
        PopUpModal,
    },
    setup() {
        const { t: translate } = useI18n();
        return {
            translate,
        };
    },
    emits: ["close", "confirm"],
});
