import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "content" };
const _hoisted_2 = { class: "switches" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_TimeAdjust = _resolveComponent("TimeAdjust");
    const _component_CheckSwitch = _resolveComponent("CheckSwitch");
    const _component_PopUpModal = _resolveComponent("PopUpModal");
    return (_openBlock(), _createBlock(_component_PopUpModal, {
        show: _ctx.show,
        title: _ctx.translate('adjust_delivery_time'),
        onClose: _ctx.save,
        onSubmit: _ctx.save,
        class: "time-modal"
    }, {
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_TimeAdjust, {
                    time: _ctx.time,
                    "onUpdate:time": _cache[0] || (_cache[0] = ($event) => ((_ctx.time) = $event))
                }, null, 8, ["time"]),
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_CheckSwitch, {
                        label: _ctx.translate('sms_notification'),
                        "unchecked-label": _ctx.translate('no'),
                        "checked-label": _ctx.translate('yes'),
                        checked: _ctx.notifications.includes('sms'),
                        "onUpdate:checked": _cache[1] || (_cache[1] = ($event) => (_ctx.changeNotifications('sms'))),
                        disabled: _ctx.smsDisabled
                    }, null, 8, ["label", "unchecked-label", "checked-label", "checked", "disabled"]),
                    _createVNode(_component_CheckSwitch, {
                        label: _ctx.translate('e_mail_notification'),
                        "unchecked-label": _ctx.translate('no'),
                        "checked-label": _ctx.translate('yes'),
                        checked: _ctx.notifications.includes('email'),
                        "onUpdate:checked": _cache[2] || (_cache[2] = ($event) => (_ctx.changeNotifications('email'))),
                        disabled: _ctx.emailDisabled
                    }, null, 8, ["label", "unchecked-label", "checked-label", "checked", "disabled"]),
                    _createVNode(_component_CheckSwitch, {
                        disabled: "",
                        label: _ctx.translate('push_notification'),
                        "unchecked-label": _ctx.translate('no'),
                        "checked-label": _ctx.translate('yes'),
                        checked: _ctx.notifications.includes('push'),
                        "onUpdate:checked": _cache[3] || (_cache[3] = ($event) => (_ctx.changeNotifications('push')))
                    }, null, 8, ["label", "unchecked-label", "checked-label", "checked"])
                ])
            ])
        ]),
        _: 1
    }, 8, ["show", "title", "onClose", "onSubmit"]));
}
