import { computed, defineComponent } from "vue";
import ButtonBase from "@/ui-elements/button-base/ButtonBase.vue";
import { useI18n } from "vue-i18n";
import OrderPrice from "@/pages/orders/order/components/OrderPrice.vue";
import DeliveryDetails from "@/pages/orders/order/components/DeliveryDetails.vue";
import { useSettingsStore } from "@/store/Settings";
export default defineComponent({
    name: "KitchenDetails",
    components: {
        ButtonBase,
        OrderPrice,
        DeliveryDetails,
    },
    props: {
        order: { type: Object, required: true },
        deliveryType: { type: Object },
        showButtons: {
            type: Boolean,
            default: false,
        },
        showRemarks: Boolean,
        showDeliverer: Boolean,
    },
    setup(props, context) {
        const { t: translate } = useI18n();
        const settings = useSettingsStore().settings;
        const label = computed(() => {
            if (props.order.source.type === "kiosk") {
                return "Kiosk";
            }
            if (props.order.source.type === "counter") {
                return props.order.label;
            }
            if (props.order.source.type === "online") {
                return "QR";
            }
            return "";
        });
        const clickHandler = (status) => {
            context.emit("status", status);
        };
        const showRefNr = computed(() => settings.ob_show_refnr === "1");
        return {
            translate,
            label,
            clickHandler,
            showRefNr,
        };
    },
    emits: ["status"],
});
