import { computed, ref, watch } from "vue";
import L from "leaflet";
import { useSettingsStore } from "@/store/Settings";
export const useMap = (mapId) => {
    const defaultZoom = ref(15);
    const map = ref(undefined);
    const tileLayer = ref({});
    const labelLayer = ref({});
    const accessToken = process.env.VUE_APP_MAPBOX_TOKEN;
    const mapType = computed(() => JSON.parse(useSettingsStore().settings.orders_map_style));
    const tileLayerConf = computed(() => L.tileLayer(
    ////// map
    `https://api.mapbox.com/styles/v1/foodticket/${mapType.value.tiles}/tiles/{z}/{x}/{y}{r}?access_token=${accessToken}`, {
        tileSize: 512,
        zoomOffset: -1,
        crossOrigin: true,
        detectRetina: true,
    }));
    const labelLayerConf = computed(() => L.tileLayer(
    ////// labels
    `https://api.mapbox.com/styles/v1/foodticket/${mapType.value.labels}/tiles/{z}/{x}/{y}{r}?access_token=${accessToken}`, {
        tileSize: 512,
        zoomOffset: -1,
        crossOrigin: true,
        pane: "labels",
        detectRetina: true,
    }));
    const mapConfig = {
        attributionControl: false,
        zoomControl: false,
        zoomSnap: 0.6,
        maxZoom: 18,
        minZoom: 0,
    };
    const setMap = (center, bounds = undefined) => {
        map.value = L.map(mapId, mapConfig).setView(center, defaultZoom.value);
        if (bounds) {
            setBoundaries(bounds);
        }
        map.value?.getPane("marker");
    };
    const addTiles = () => {
        if (map.value) {
            if (tileLayer.value) {
                map.value?.removeLayer(tileLayerConf.value);
            }
            tileLayer.value = tileLayerConf.value.addTo(map.value);
            if (!map.value?.getPane("labels")) {
                map.value.createPane("labels");
            }
            if (labelLayer.value) {
                map.value?.removeLayer(labelLayerConf.value);
            }
            labelLayer.value = labelLayerConf.value.addTo(map.value);
        }
    };
    watch(() => useSettingsStore().settings.orders_map_style, () => {
        addTiles();
    });
    const setBoundaries = (bounds, padding = [20, 20]) => {
        if (map.value) {
            map.value.fitBounds(bounds, { padding: padding });
        }
    };
    return {
        defaultZoom,
        map,
        addTiles,
        setMap,
        setBoundaries,
    };
};
export const toCenter = (coords, map) => {
    map.setView(new L.LatLng((coords[0][0] + coords[1][0]) / 2, (coords[0][1] + coords[1][1]) / 2));
};
export function getFeatureFromZip(zip, geometries) {
    const filtered = geometries.features.filter((feature) => {
        return feature.properties?.id === zip;
    });
    return filtered ? filtered[0] : null;
}
export const zipLayerConf = {
    pointToLayer: (feature, latlng) => {
        const label = String(feature.properties?.id);
        return new L.CircleMarker(latlng, {
            className: "marker",
            interactive: false,
        })
            .bindTooltip(label, {
            permanent: true,
            opacity: 1.0,
            direction: "center",
        })
            .openTooltip();
    },
};
export const vectorLayerStyles = {
    sliced: {
        color: "#e06633",
        fill: true,
        fillColor: "#e06633",
        fillOpacity: 0.1,
    },
};
export const vectorLayerConf = {
    vectorTileLayerName: "sliced",
    vectorTileLayerStyles: vectorLayerStyles,
    getFeatureId: (feature) => feature.properties?.id,
    maxZoom: 16,
    tolerance: 3,
    interactive: true,
    updateWhenZooming: false,
};
export const highlightStyle = {
    //// style of the hovered zip area
    weight: 1,
    color: "#E10606",
    opacity: 1,
    fill: true,
    fillColor: "#E10606",
    fillOpacity: 0.4,
};
