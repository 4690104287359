import { useFormatMoney } from "@/utils/useCurrencySymbol";
import { useSettingsStore } from "@/store/Settings";
export const parseProductExtras = (extras) => {
    const settingsStore = useSettingsStore();
    return extras.map((extra) => {
        const quant = extra.quantity > 1 ? String(extra.quantity) + "x " : "";
        const title = Number(settingsStore.settings.orderpopup_products_kitchentitle) &&
            extra.kitchen_title
            ? extra.kitchen_title
            : extra.title;
        const price = extra.price
            ? " (" + useFormatMoney(extra.price) + ")"
            : "";
        return quant + title + price;
    });
};
