import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
    class: "page-nav",
    "aria-label": "Page navigation"
};
const _hoisted_2 = { class: "pagination" };
const _hoisted_3 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SelectComponent = _resolveComponent("SelectComponent");
    const _component_IconBase = _resolveComponent("IconBase");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.hidePerPage)
            ? (_openBlock(), _createBlock(_component_SelectComponent, {
                key: 0,
                class: "per-page-select",
                "row-height": _ctx.smallerHeight,
                options: _ctx.perPageOptions,
                value: _ctx.pagination.per_page,
                "can-search": false,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('update:pagination', { ..._ctx.pagination, per_page: $event }))),
                name: "perpage",
                text: _ctx.translate('per_page')
            }, null, 8, ["row-height", "options", "value", "text"]))
            : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
                class: _normalizeClass(["page-navigation page-item", { disabled: _ctx.pagination.current_page === 1 }]),
                onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.changePage(_ctx.pagination.current_page - 1)))
            }, [
                _createVNode(_component_IconBase, { icon: "double-arrow-left" })
            ], 2),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pageList, (p) => {
                return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(["page-item", { active: _ctx.pagination.current_page === p, dots: p < 0 }]),
                    onClick: ($event) => (_ctx.changePage(p)),
                    key: p
                }, [
                    (p > 0)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createTextVNode(_toDisplayString(p), 1)
                        ], 64))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createTextVNode("...")
                        ], 64))
                ], 10, _hoisted_3));
            }), 128)),
            _createElementVNode("div", {
                class: _normalizeClass(["page-navigation page-item", {
                        disabled: _ctx.pagination.current_page === _ctx.pagination.last_page,
                    }]),
                onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.changePage(_ctx.pagination.current_page + 1)))
            }, [
                _createVNode(_component_IconBase, { icon: "double-arrow-right" })
            ], 2)
        ])
    ]));
}
