import { useSettingsStore } from "@/store/Settings";
import { computed } from "vue";
import { darker, lighter } from "@/utils/useColor";
import { multiLocationStore } from "@/store/MultiLocation";
const dispatchColors = {
    deliver: "delivery_orders_color",
    pickup: "pickup_orders_color",
};
const sourceToColorSetting = {
    tb: "tb_orders_color",
    de: "de_orders_color",
    ue: "ue_orders_color",
};
export const getOrderColor = (order) => {
    const settings = useSettingsStore().settings;
    const multiLocationSettings = multiLocationStore().location_settings;
    // In the main account of multi location, we want to use just one color per restaurant
    if (multiLocationStore().isActive &&
        order.client_id in multiLocationSettings &&
        multiLocationStore().mainLocationSelected) {
        return multiLocationStore().location_settings[order.client_id]["orders_color"];
    }
    const setting = dispatchColors[order.delivery];
    if (!setting) {
        return "";
    }
    const colorSetting = sourceToColorSetting[order.source.type];
    if (colorSetting) {
        return (settings[colorSetting] || "");
    }
    return (settings[setting] || "");
};
export const useOrderColor = (order) => {
    const orderColor = computed(() => getOrderColor(order.value));
    const darkerColor = computed(() => {
        const color = orderColor.value;
        return color ? darker(color) : "";
    });
    const lighterColor = computed(() => {
        const color = orderColor.value;
        return color ? lighter(color) : "";
    });
    const filter = computed(() => {
        return darkerColor.value
            ? "drop-shadow(0 0 0.1rem " + darkerColor.value + ")"
            : "";
    });
    const background = computed(() => {
        return lighterColor.value && darkerColor.value
            ? "linear-gradient(90deg," +
                lighterColor.value +
                " 0%, " +
                darkerColor.value +
                " 100%)"
            : "";
    });
    return { orderColor, filter, background };
};
