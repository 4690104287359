import { computed, defineComponent, ref } from "vue";
import { IconBase } from "@/ui-elements";
import { Subject } from "rxjs";
import { useSubscription } from "@vueuse/rxjs";
import { map } from "rxjs/operators";
import { useI18n } from "vue-i18n";
import OptionList from "@/ui-elements/input/OptionList.vue";
export default defineComponent({
    name: "OrdersFilter",
    components: {
        IconBase,
        OptionList,
    },
    props: {
        statusFilter: {
            type: Array,
            required: true,
        },
    },
    setup(props) {
        const { t: translate } = useI18n();
        const filtersOpen = ref(false);
        const openSubject = new Subject();
        useSubscription(openSubject.pipe(map(() => !filtersOpen.value)).subscribe((_) => {
            filtersOpen.value = _;
        }));
        const statusTranslations = {
            init: translate("new"),
            pending: translate("kitchen"),
            "pending-ready": translate("ready"),
            enroute: translate("orders_map_filters_enroute"),
            delivered: translate("delivered"),
            deliverers: translate("deliverers"),
        };
        const selectedOptions = computed(() => props.statusFilter
            .map((status) => statusTranslations[status])
            .join(", "));
        const filterOptions = ref([
            {
                label: statusTranslations.init,
                value: "init",
            },
            {
                label: statusTranslations.pending,
                value: "pending",
            },
            {
                label: statusTranslations["pending-ready"],
                value: "pending-ready",
            },
            {
                label: statusTranslations.enroute,
                value: "enroute",
            },
            {
                label: statusTranslations.delivered,
                value: "delivered",
            },
            {
                label: translate("deliverers"),
                value: "deliverers",
            },
        ]);
        return {
            translate,
            filtersOpen,
            openSubject,
            selectedOptions,
            filterOptions,
        };
    },
    emits: ["update:statusFilter"],
});
