import { computed, defineComponent } from "vue";
import { toDateMonthFormat } from "@/utils/useRefactorDate";
export default defineComponent({
    name: "MessageSingle",
    props: {
        message: {
            type: Object,
            default: () => { },
        },
        selected: Boolean,
        isNew: Boolean,
    },
    setup(props) {
        const stripHtmlTags = (text) => text.replace(/(<([^>]+)>)/gi, "").replace(/&nbsp;/g, " ");
        const date = computed(() => {
            const array = toDateMonthFormat(props.message.created_at).split(" ");
            if (array.length !== 2) {
                return ["", ""];
            }
            else {
                return array;
            }
        });
        return {
            toDateMonthFormat,
            stripHtmlTags,
            date,
        };
    },
});
