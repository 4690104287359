import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ButtonBase = _resolveComponent("ButtonBase");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_ButtonBase, {
            ref: "downButton",
            disabled: !_ctx.scrollableDown,
            variant: "filled",
            type: "regular",
            class: "page-down",
            onMousedown: _cache[0] || (_cache[0] = ($event) => (_ctx.scroll(1))),
            onMouseup: _cache[1] || (_cache[1] = ($event) => (_ctx.stopConstantScroll())),
            icon: "arrow-down"
        }, null, 8, ["disabled"]),
        _createVNode(_component_ButtonBase, {
            ref: "upButton",
            disabled: !_ctx.scrollableUp,
            variant: "filled",
            type: "regular",
            class: "page-up",
            onMousedown: _cache[2] || (_cache[2] = ($event) => (_ctx.scroll(-1))),
            onMouseup: _cache[3] || (_cache[3] = ($event) => (_ctx.stopConstantScroll())),
            icon: "arrow-up"
        }, null, 8, ["disabled"]),
        (_ctx.scrolledDown)
            ? (_openBlock(), _createBlock(_component_ButtonBase, {
                key: 0,
                variant: "filled",
                type: "regular",
                class: "to-top",
                icon: "chevron-up",
                onClick: _ctx.scrollToTop
            }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
    ], 64));
}
