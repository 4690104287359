import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2a5db947"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "order-status-options" };
const _hoisted_2 = ["onClick"];
const _hoisted_3 = { class: "status-label" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_StatusIcon = _resolveComponent("StatusIcon");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statusOptions, (order_status) => {
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["order-status", { selected: _ctx.status === order_status.value }]),
                key: order_status.key,
                onClick: ($event) => (_ctx.$emit('update:status', order_status.value))
            }, [
                _createVNode(_component_StatusIcon, {
                    "order-status": order_status.value
                }, null, 8, ["order-status"]),
                _createElementVNode("div", _hoisted_3, _toDisplayString(order_status.label), 1)
            ], 10, _hoisted_2));
        }), 128))
    ]));
}
