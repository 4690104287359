import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "image-wrap" };
const _hoisted_2 = { class: "file-name" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ImageOrVideo = _resolveComponent("ImageOrVideo");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["image-component", { selected: _ctx.selected }]),
        onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('select')))
    }, [
        _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ImageOrVideo, {
                src: _ctx.src,
                alt: _ctx.getFileNameFormPath(_ctx.src)
            }, null, 8, ["src", "alt"])
        ]),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.getFileNameFormPath(_ctx.src)), 1)
    ], 2));
}
