import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
    class: "search-field",
    ref: "parent"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_TextField = _resolveComponent("TextField");
    const _component_SearchResults = _resolveComponent("SearchResults");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_TextField, {
            text: _ctx.customerStore.search,
            "onUpdate:text": _cache[0] || (_cache[0] = ($event) => ((_ctx.customerStore.search) = $event)),
            placeholder: _ctx.translate('pos_modal_customer_search_placeholder'),
            onFocus: _cache[1] || (_cache[1] = ($event) => (_ctx.focussed = true)),
            icon: "search",
            class: _normalizeClass({ open: _ctx.showSearchResults }),
            "data-testid": "search"
        }, null, 8, ["text", "placeholder", "class"]),
        (_ctx.showSearchResults)
            ? (_openBlock(), _createBlock(_component_SearchResults, {
                key: 0,
                onSelect: _ctx.onSelect
            }, null, 8, ["onSelect"]))
            : _createCommentVNode("", true)
    ], 512));
}
