import axios from "axios";
import i18n from "@/i18n";
import { round, toLower } from "lodash";
export const getRoute = (coords, transportMode = "driving") => {
    const { t: translate } = i18n.global;
    const secondsToTimeString = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const hoursString = hours === 0 ? "" : hours < 10 ? `0${hours}:` : `${hours}:`;
        const minutesString = minutes < 10 && hours > 0 ? `0${minutes}` : `${minutes}`;
        return `${hoursString}${minutesString}`;
    };
    return axios
        .post("/client/geo/here/route", {
        mode: transportMode,
        origin: coords[0].join(","),
        destination: coords[1].join(","),
    })
        .then((response) => {
        if (Array.isArray(response.data.data.route) &&
            !response.data.length) {
            throw Error;
        }
        const summary = response.data.data.summary;
        const formattedText = translate("route_map_dis_dur", {
            distance: round(summary.distance / 1000, 2),
            duration: secondsToTimeString(summary.travelTime),
            hourOrMinutes: secondsToTimeString(summary.travelTime).includes(":")
                ? toLower(translate("hour"))
                : toLower(translate("minutes")),
        });
        return {
            route: response.data.data.route,
            summary: {
                ...summary,
                formattedText,
            },
        };
    });
};
