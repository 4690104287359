import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-cabb1012"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    class: "search-results",
    tabindex: "-1"
};
const _hoisted_2 = {
    class: "results",
    ref: "scrollArea",
    tabindex: "-1"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SearchResult = _resolveComponent("SearchResult");
    const _component_LoadingIndicator = _resolveComponent("LoadingIndicator");
    const _component_PageFooter = _resolveComponent("PageFooter");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modalStore.results, (result) => {
                return (_openBlock(), _createBlock(_component_SearchResult, {
                    property: _ctx.property,
                    key: result.id,
                    result: result,
                    onClick: ($event) => (_ctx.onClick(result))
                }, null, 8, ["property", "result", "onClick"]));
            }), 128)),
            (_ctx.loading)
                ? (_openBlock(), _createBlock(_component_LoadingIndicator, {
                    key: 0,
                    cover: ""
                }))
                : _createCommentVNode("", true)
        ], 512),
        _createVNode(_component_PageFooter, {
            ready: true,
            "scroll-area": _ctx.scrollArea
        }, null, 8, ["scroll-area"])
    ]));
}
