import { computed, defineComponent, ref, watch } from "vue";
import { useCustomerModalStore } from "@/store/CustomerModal";
import { useI18n } from "vue-i18n";
import PhoneInput from "@/ui-elements/input/PhoneInput.vue";
import SearchResults from "@/pages/pos/modal/customer/search/SearchResults.vue";
import ButtonBase from "@/ui-elements/button-base/ButtonBase.vue";
import { calculateDirection, calculateDisplay, useFocus, } from "@/pages/pos/modal/customer/data/useSearchResults";
import { useCartStore } from "@/store/cart/Cart";
import { useDebounce } from "@/utils/useDebounce";
import { useClickToDial } from "@/utils/clickToDial/useClickToDial";
export default defineComponent({
    name: "PhoneField",
    components: {
        PhoneInput,
        SearchResults,
        ButtonBase,
    },
    props: {
        boundingElement: {
            type: HTMLElement,
        },
        error: Boolean,
    },
    setup(props) {
        const { t: translate } = useI18n();
        const customerStore = useCustomerModalStore();
        const cartStore = useCartStore();
        const focussed = ref(false);
        const display = computed(() => calculateDisplay(focussed.value, cartStore.customer.address, "phone", customerStore.results));
        watch([() => cartStore.customer.address.phone, focussed], useDebounce(() => {
            if (focussed.value && cartStore.customer.address.phone) {
                customerStore.fetchResults(cartStore.customer.address.phone, "phone");
            }
        }, 300));
        const inputField = ref();
        const upOrDown = computed(() => calculateDirection(props.boundingElement, inputField.value?.$el));
        const { callsEnabled, dial } = useClickToDial();
        const hasPhone = computed(() => cartStore.customer.address.phone);
        const call = async () => {
            dial(cartStore.customer.address.phone);
        };
        const parent = useFocus(focussed);
        return {
            translate,
            cartStore,
            focussed,
            display,
            parent,
            inputField,
            upOrDown,
            callsEnabled,
            hasPhone,
            call,
        };
    },
    emits: ["validation"],
});
