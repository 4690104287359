import SearchResults from "@/pages/pos/modal/customer/search/SearchResults.vue";
import { computed, defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { TextField } from "@/ui-elements";
import { useCustomerModalStore } from "@/store/CustomerModal";
import { useFocus } from "@/pages/pos/modal/customer/data/useSearchResults";
import { useDebounce } from "@/utils/useDebounce";
export default defineComponent({
    name: "SearchField",
    components: {
        TextField,
        SearchResults,
    },
    setup() {
        const { t: translate } = useI18n();
        const customerStore = useCustomerModalStore();
        const focussed = ref(false);
        if (customerStore.isCallerId) {
            focussed.value = true;
        }
        const showSearchResults = computed(() => Boolean(focussed.value && (customerStore.search ?? "").length > 2));
        watch([() => customerStore.search, focussed], useDebounce(() => {
            if (focussed.value && customerStore.search) {
                customerStore.fetchResults(customerStore.search, "search");
            }
        }, 300));
        const onSelect = () => {
            focussed.value = false;
            customerStore.search = "";
        };
        const parent = useFocus(focussed);
        return {
            translate,
            showSearchResults,
            focussed,
            customerStore,
            onSelect,
            parent,
        };
    },
});
