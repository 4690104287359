import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "sub-menu" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_IconBase = _resolveComponent("IconBase");
    const _component_router_link = _resolveComponent("router-link");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuOptions, (link) => {
            return (_openBlock(), _createBlock(_component_router_link, {
                key: link.text,
                class: _normalizeClass(["sub-menu-link", { current: _ctx.currentPage === link.name }]),
                to: { name: link.name }
            }, {
                default: _withCtx(() => [
                    (link.icon)
                        ? (_openBlock(), _createBlock(_component_IconBase, {
                            key: 0,
                            icon: link.icon,
                            class: _normalizeClass(link.iconClass)
                        }, null, 8, ["icon", "class"]))
                        : _createCommentVNode("", true),
                    _createTextVNode(" " + _toDisplayString(_ctx.translations ? _ctx.translate(link.text) : link.text), 1)
                ]),
                _: 2
            }, 1032, ["to", "class"]));
        }), 128))
    ]));
}
