import { defineComponent, ref } from "vue";
import SearchResult from "@/pages/pos/modal/customer/search/SearchResult.vue";
import PageFooter from "@/ui-elements/layout/footer/PageFooter.vue";
import { useCartStore } from "@/store/cart/Cart";
import { useCustomerModalStore } from "@/store/CustomerModal";
import LoadingIndicator from "@/ui-elements/loaders/LoadingIndicator.vue";
export default defineComponent({
    name: "SearchResults",
    components: { SearchResult, PageFooter, LoadingIndicator },
    props: {
        property: {
            type: String,
            default: "",
        },
        loading: {
            type: Boolean,
        },
    },
    setup(props, context) {
        const cartStore = useCartStore();
        const modalStore = useCustomerModalStore();
        const scrollArea = ref();
        const onClick = (customer) => {
            cartStore.saveCustomer(customer);
            context.emit("select");
        };
        return {
            modalStore,
            onClick,
            scrollArea,
        };
    },
});
