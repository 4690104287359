import { paramsSerializer } from "@/utils/api/paramsSerializer";
import { computed, defineComponent, ref } from "vue";
import { PopUpModal } from "@/ui-elements";
import { useI18n } from "vue-i18n";
import TextField from "@/ui-elements/input/text-field/TextField.vue";
import axios from "axios";
import FormErrorsProvider from "@/ui-elements/FormErrorsProvider.vue";
import { useFormErrors } from "@/utils/useFormErrors";
import { useUserStore } from "@/store/User";
import { ApiError } from "@/interface/api/ApiError";
import { setToastNotification } from "@/ui-elements/toast-notification/setToastNotification";
import { multiLocationStore } from "@/store/MultiLocation";
export default defineComponent({
    name: "OrderDeletePopUp",
    components: {
        FormErrorsProvider,
        PopUpModal,
        TextField,
    },
    props: {
        id: Number,
        noCommission: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, context) {
        const { t: translate } = useI18n();
        const reason = ref("");
        const pin = ref("");
        const userStore = useUserStore();
        const isAdmin = computed(() => !!Number(userStore.user.is_admin));
        const { formErrors, handleApiFormErrors, clearFormErrors } = useFormErrors();
        const deleteOrder = async () => {
            clearFormErrors();
            axios
                .delete("/client/archive/" + props.id, {
                params: {
                    main_location_id: multiLocationStore().isActive
                        ? multiLocationStore().main_location_id
                        : 0,
                },
                paramsSerializer: paramsSerializer,
                data: {
                    pincode: pin.value,
                    reason: reason.value,
                    no_commission: props.noCommission,
                },
            })
                .then(() => {
                context.emit("success");
                context.emit("close");
            })
                .catch((error) => {
                const errorCode = error.response.data.error;
                if (errorCode === ApiError.READONLY) {
                    context.emit("close");
                    setToastNotification(translate("something_went_wrong"), error.response.data.message, "danger");
                    return;
                }
                handleApiFormErrors(error);
            });
        };
        return {
            translate,
            reason,
            pin,
            deleteOrder,
            formErrors,
            isAdmin,
        };
    },
    emits: ["close", "success"],
});
