import { computed, defineComponent } from "vue";
import Tag from "@/ui-elements/Tag.vue";
import { useFormatCurrency } from "@/utils/useCurrencySymbol";
import { useI18n } from "vue-i18n";
import { isActuallyPaid, isPinFinal } from "@/utils/useIsOrderPaid";
export default defineComponent({
    name: "OrderPrice",
    components: {
        Tag,
    },
    props: {
        order: {
            type: Object,
            default: () => { },
        },
    },
    setup(props) {
        const { t: translate } = useI18n();
        const tagStyle = computed(() => props.order.paid_at !== null ? "success" : "secondary");
        const paymentTotal = computed(() => useFormatCurrency(props.order.total));
        const paymentStatus = computed(() => {
            if (!props.order) {
                return "";
            }
            return translate("var_order.paymentStatus." + props.order.payment.status);
        });
        return {
            tagStyle,
            paymentStatus,
            paymentTotal,
            isActuallyPaid,
            isPinFinal,
        };
    },
});
