import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
    class: "font relative ml-6",
    ref: "modifier"
};
const _hoisted_2 = {
    key: 0,
    class: "font-size-modifier fixed"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_IconBase = _resolveComponent("IconBase");
    const _component_VueSlider = _resolveComponent("VueSlider");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
                class: "font-button cursor-pointer",
                onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.open = !_ctx.open))
            }, [
                _createVNode(_component_IconBase, { icon: "text-font-size text-xl" }),
                _createVNode(_component_IconBase, { icon: "chevron-down text-xs" })
            ])
        ], 512),
        (_ctx.open)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_VueSlider, _mergeProps({
                    modelValue: _ctx.value,
                    onChange: _cache[1] || (_cache[1] = ($event) => (_ctx.value = $event))
                }, _ctx.sliderConfig, { data: _ctx.sizeOptions }), null, 16, ["modelValue", "data"])
            ]))
            : _createCommentVNode("", true)
    ], 64));
}
