import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = ["innerHTML"];
const _hoisted_2 = { class: "inputs" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_TextField = _resolveComponent("TextField");
    const _component_FormErrorsProvider = _resolveComponent("FormErrorsProvider");
    const _component_PopUpModal = _resolveComponent("PopUpModal");
    return (_openBlock(), _createBlock(_component_PopUpModal, {
        show: "",
        title: _ctx.noCommission
            ? _ctx.translate('delete_no_commission')
            : _ctx.translate('delete_order'),
        onClose: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('close'))),
        onSubmit: _ctx.deleteOrder,
        class: "delete-order",
        submitButtonText: _ctx.translate('confirm')
    }, {
        default: _withCtx(() => [
            _createElementVNode("span", {
                innerHTML: _ctx.translate('archive_edit_delete_popup_description')
            }, null, 8, _hoisted_1),
            _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_FormErrorsProvider, { errors: _ctx.formErrors }, {
                    default: _withCtx(() => [
                        _createVNode(_component_TextField, {
                            type: "text",
                            text: _ctx.reason,
                            "onUpdate:text": _cache[0] || (_cache[0] = ($event) => ((_ctx.reason) = $event)),
                            label: _ctx.translate('reason'),
                            name: "reason"
                        }, null, 8, ["text", "label"]),
                        (!_ctx.isAdmin)
                            ? (_openBlock(), _createBlock(_component_TextField, {
                                key: 0,
                                type: "password",
                                text: _ctx.pin,
                                "onUpdate:text": _cache[1] || (_cache[1] = ($event) => ((_ctx.pin) = $event)),
                                label: "PIN",
                                name: "pincode",
                                autocomplete: "new-password"
                            }, null, 8, ["text"]))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                }, 8, ["errors"])
            ])
        ]),
        _: 1
    }, 8, ["title", "onSubmit", "submitButtonText"]));
}
