import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "emergency-brake-tab webshop-tap" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_NotificationComponent = _resolveComponent("NotificationComponent");
    const _component_CheckSwitch = _resolveComponent("CheckSwitch");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_NotificationComponent, { "notification-type": "error" }, {
            notificationLabel: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("components_brake_close_message")), 1)
            ]),
            notificationMessage: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("components_brake_message")), 1)
            ]),
            _: 1
        }),
        _createElementVNode("h3", null, _toDisplayString(_ctx.translate("webshop_closed")), 1),
        _createVNode(_component_CheckSwitch, {
            checked: _ctx.settings.closed_tmp,
            "onUpdate:checked": _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('update:settings', {
                ..._ctx.settings,
                closed_tmp: $event,
            }))),
            "checked-label": _ctx.translate('webshop_closed'),
            "unchecked-label": _ctx.translate('webshop_open'),
            "uncheck-icon": "shopping-store",
            "check-icon": "warning"
        }, null, 8, ["checked", "checked-label", "unchecked-label"])
    ]));
}
