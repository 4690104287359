import { computed, defineComponent } from "vue";
import { IconBase } from "@/ui-elements";
import { toLongDateFormat } from "@/utils/useRefactorDate";
import { useI18n } from "vue-i18n";
import { multiLocationStore } from "@/store/MultiLocation";
export default defineComponent({
    name: "DelivererTile",
    components: {
        IconBase,
    },
    props: {
        deliverer: {
            type: Object,
            required: true,
        },
        selected: Boolean,
        moreData: Boolean,
    },
    setup(props) {
        const { t: translate } = useI18n();
        const date = computed(() => {
            const formattedDate = toLongDateFormat(props.deliverer.coordinates_updated_at);
            return isNaN(new Date(formattedDate).getTime())
                ? "-"
                : formattedDate;
        });
        return { date, translate, multiLocation: multiLocationStore() };
    },
});
