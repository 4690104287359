import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "orders-sidebar" };
const _hoisted_2 = { class: "header" };
const _hoisted_3 = { class: "title" };
const _hoisted_4 = {
    class: "body",
    ref: "scrollArea"
};
const _hoisted_5 = {
    key: 0,
    class: "orders-list"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ButtonBase = _resolveComponent("ButtonBase");
    const _component_OrdersFilter = _resolveComponent("OrdersFilter");
    const _component_OrderTile = _resolveComponent("OrderTile");
    const _component_DelivererTile = _resolveComponent("DelivererTile");
    const _component_PageFooter = _resolveComponent("PageFooter");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.translate("all_orders")), 1),
            _createVNode(_component_ButtonBase, {
                name: "close",
                icon: "delete",
                onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('close')))
            })
        ]),
        _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_OrdersFilter, {
                statusFilter: _ctx.statusFilter,
                "onUpdate:statusFilter": _cache[1] || (_cache[1] = ($event) => (_ctx.updateMapModalFilters($event)))
            }, null, 8, ["statusFilter"]),
            (_ctx.ready)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    (!_ctx.orders.length)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createTextVNode(_toDisplayString(_ctx.translate("orders_map_noOrders")), 1)
                        ], 64))
                        : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orders, (order) => {
                        return (_openBlock(), _createBlock(_component_OrderTile, {
                            key: order.id,
                            order: order,
                            "is-for-map": true,
                            onMouseover: ($event) => (_ctx.$emit('hover', order.id)),
                            onMouseleave: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('hover', 0))),
                            highlight: _ctx.highlight === order.id
                        }, null, 8, ["order", "onMouseover", "highlight"]));
                    }), 128)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.deliverers, (deliverer) => {
                        return (_openBlock(), _createBlock(_component_DelivererTile, {
                            key: deliverer.id,
                            deliverer: deliverer,
                            "more-data": true,
                            onMouseover: ($event) => (_ctx.$emit('hover', deliverer.id)),
                            onMouseleave: _cache[3] || (_cache[3] = ($event) => (_ctx.$emit('hover', 0))),
                            class: _normalizeClass({ highlight: _ctx.highlight === deliverer.id })
                        }, null, 8, ["deliverer", "onMouseover", "class"]));
                    }), 128))
                ]))
                : _createCommentVNode("", true)
        ], 512),
        _createVNode(_component_PageFooter, { "scroll-area": _ctx.scrollArea }, {
            default: _withCtx(() => [
                _createVNode(_component_ButtonBase, {
                    name: "save",
                    onClick: _cache[4] || (_cache[4] = ($event) => (_ctx.$emit('close')))
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("close")), 1)
                    ]),
                    _: 1
                })
            ]),
            _: 1
        }, 8, ["scroll-area"])
    ]));
}
