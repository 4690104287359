import { defineComponent } from "vue";
import PageTitle from "@/ui-elements/PageTitle.vue";
export default defineComponent({
    name: "SaldoDisplay",
    components: {
        PageTitle,
    },
    props: {
        data: Object,
    },
});
