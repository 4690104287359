import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useCartStore } from "@/store/cart/Cart";
import CheckSwitch from "@/ui-elements/input/check-switch/CheckSwitch.vue";
export default defineComponent({
    name: "MailingField",
    components: { CheckSwitch },
    setup() {
        const { t: translate } = useI18n();
        const cartStore = useCartStore();
        return {
            translate,
            cartStore,
        };
    },
});
