import { defineComponent, ref, toRef } from "vue";
import { useScroll } from "@/utils/useScroll";
import { onLongPress } from "@vueuse/core";
import ButtonBase from "@/ui-elements/button-base/ButtonBase.vue";
export default defineComponent({
    name: "ScrollButtons",
    components: {
        ButtonBase,
    },
    props: {
        scrollArea: {
            type: Object,
        },
    },
    setup(props) {
        const { scrollableDown, scrollableUp, scrolledDown, scroll, scrollToTop, stopConstantScroll, startConstantScroll, } = useScroll(toRef(props, "scrollArea"));
        const downButton = ref();
        const upButton = ref();
        onLongPress(downButton, () => startConstantScroll(1), { delay: 200 });
        onLongPress(upButton, () => startConstantScroll(-1), { delay: 200 });
        return {
            scrollableDown,
            scrollableUp,
            scrollToTop,
            scrolledDown,
            scroll,
            downButton,
            upButton,
            stopConstantScroll,
        };
    },
});
