import { defineComponent } from "vue";
import { useSettingsStore } from "@/store/Settings";
import { storeToRefs } from "pinia";
import { useOrdersStore } from "@/store/Orders";
export default defineComponent({
    name: "OrderAudioNotification",
    props: {
        autoPrint: {
            type: Boolean,
            default: false,
        },
        once: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { settings } = storeToRefs(useSettingsStore());
        if (props.autoPrint) {
            setTimeout(() => (useOrdersStore().playSoundOnce = false), 3000);
        }
        return {
            settings,
        };
    },
});
