import { computed, defineComponent, ref, watch } from "vue";
import { formatLeadingZero } from "@/utils/useLeadingZero";
import { onLongPress } from "@vueuse/core";
export default defineComponent({
    name: "TimeAdjust",
    props: {
        time: {
            type: String,
            required: true,
        },
    },
    setup(props, context) {
        const plusButton = ref();
        const minusButton = ref();
        const refactorTime = (time) => {
            if (time) {
                const split = time.split(":");
                if (split.length >= 2) {
                    return {
                        hours: parseInt(split[0]),
                        minutes: parseInt(split[1]),
                    };
                }
            }
            console.warn("TimeAdjust: Time prop has wrong form.");
            return {
                hours: 0,
                minutes: 0,
            };
        };
        const refactoredTime = computed(() => refactorTime(props.time));
        const update = (hours, minutes) => {
            context.emit("update:time", formatLeadingZero(hours) + ":" + formatLeadingZero(minutes));
        };
        const changeTime = (dir, amount) => {
            const newMinutes = refactoredTime.value.minutes + dir * amount;
            if (newMinutes >= 60) {
                update((refactoredTime.value.hours + 1) % 24, newMinutes % 60);
            }
            else if (newMinutes >= 0) {
                update(refactoredTime.value.hours, newMinutes);
            }
            else if (newMinutes < 0) {
                update((refactoredTime.value.hours - 1) % 24, 60 + newMinutes);
            }
        };
        const longPressMinus = ref(false);
        const longPressPlus = ref(false);
        onLongPress(minusButton, () => (longPressMinus.value = true), {
            delay: 200,
        });
        onLongPress(plusButton, () => (longPressPlus.value = true), {
            delay: 200,
        });
        const repeatChange = (longPressRef, counterRef, dir) => {
            if (counterRef.value < 600) {
                if (longPressRef.value && counterRef.value < 4) {
                    setTimeout(() => {
                        changeTime(dir, 5);
                        counter.value++;
                        repeatChange(longPressRef, counterRef, dir);
                    }, 400);
                }
                else if (longPressRef.value) {
                    setTimeout(() => {
                        changeTime(dir, 5);
                        counter.value++;
                        repeatChange(longPressRef, counterRef, dir);
                    }, 100);
                }
                else {
                    counterRef.value = 0;
                }
            }
        };
        const counter = ref(0);
        watch(longPressMinus, () => repeatChange(longPressMinus, counter, -1));
        watch(longPressPlus, () => repeatChange(longPressPlus, counter, 1));
        return {
            refactoredTime,
            changeTime,
            formatLeadingZero,
            plusButton,
            minusButton,
            longPressMinus,
            longPressPlus,
        };
    },
});
