import { defineComponent } from "vue";
import { getFileNameFormPath } from "@/ui-elements/file-browser/useFileBrowser";
export default defineComponent({
    name: "ImageOrVideo",
    props: {
        src: {
            type: String,
            default: "",
        },
    },
    setup() {
        return {
            getFileNameFormPath,
        };
    },
});
