import L from "leaflet";
import { coordinateToLatLng } from "@/utils/useCoordinate";
const delivererIcon = L.divIcon({
    iconSize: [32, 32],
    className: "deliverer",
    html: '<div class="inner"><div class="icon-base icon-scooter"></div></div>',
});
const foodTicketIcon = L.divIcon({
    iconSize: [32, 32],
    className: "marker",
    html: '<div class="inner"><div class="icon-base icon-foodticket"></div></div>',
});
export const drawDelivererMarker = (coords) => {
    const position = new L.LatLng(coords[0], coords[1]);
    return L.marker(position, { icon: delivererIcon });
};
export const drawOrderMarker = (coords, number = 0) => {
    const position = new L.LatLng(coords[0], coords[1]);
    const orderIcon = L.divIcon({
        iconSize: [32, 32],
        className: "order",
        html: `<div class="inner">${number || ""}</div>`,
    });
    return L.marker(position, { icon: orderIcon });
};
export const drawFoodTicketMarker = (coords) => {
    const position = new L.LatLng(coords[0], coords[1]);
    return L.marker(position, { icon: foodTicketIcon });
};
export const drawCross = (map, coordinates) => {
    const verticalPoints = [
        [-90, coordinates[1]],
        [90, coordinates[1]],
    ].map((p) => coordinateToLatLng(p));
    const horizontalPoints = [
        [coordinates[0], -180],
        [coordinates[0], 180],
    ].map((p) => coordinateToLatLng(p));
    L.polyline(verticalPoints, { color: "#bbb" }).addTo(map);
    L.polyline(horizontalPoints, { color: "#bbb" }).addTo(map);
};
