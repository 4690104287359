import IconBase from "@/ui-elements/IconBase.vue";
import { computed, ref, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import SelectComponent from "@/ui-elements/input/select/SelectComponent.vue";
import { getPerPageOptions } from "@/ui-elements/layout/footer/utils/utils";
export default defineComponent({
    name: "PaginationComponent",
    components: { IconBase, SelectComponent },
    props: {
        pagination: {
            type: Object,
            required: true,
            validator: (value) => {
                const keys = Object.keys(value);
                return (keys.includes("current_page") &&
                    keys.includes("last_page") &&
                    keys.includes("total") &&
                    keys.includes("per_page"));
            },
        },
        hidePerPage: Boolean,
        perPageCustomOptions: {
            type: Array,
        },
    },
    setup(props, context) {
        const { t: translate } = useI18n();
        const pageList = computed(() => {
            const current = props.pagination.current_page;
            const last = props.pagination.last_page;
            if (last < 5) {
                return Array.from(Array(last), (x, index) => index + 1);
            }
            else if (current < 3) {
                const start = Array.from(Array(2), (x, index) => index + 1);
                return start.concat([-1, last]);
            }
            else if (current > last - 2) {
                const end = Array.from(Array(2), (x, index) => last - 1 + index);
                return [1, -1].concat(end);
            }
            else {
                return [1, -1].concat(current).concat([-1, last]);
            }
        });
        const perPageOptions = ref(props.perPageCustomOptions || getPerPageOptions());
        const changePage = (page) => {
            if (page > 0 && page <= props.pagination.last_page) {
                context.emit("update:pagination", {
                    ...props.pagination,
                    current_page: page,
                });
            }
        };
        const smallerHeight = 30;
        return {
            pageList,
            changePage,
            perPageOptions,
            translate,
            smallerHeight,
        };
    },
    emits: ["update:pagination"],
});
