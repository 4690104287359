import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "border" };
const _hoisted_2 = {
    key: 0,
    class: "visual"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LabelComponent = _resolveComponent("LabelComponent");
    return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.errorComponent), _mergeProps(_ctx.errorComponentConfig, {
        class: ["input-field-wrapper", { disabled: _ctx.disabled }]
    }), {
        default: _withCtx(() => [
            (_ctx.label || _ctx.labelIcon)
                ? (_openBlock(), _createBlock(_component_LabelComponent, {
                    key: 0,
                    label: _ctx.label,
                    "label-icon": _ctx.labelIcon,
                    "label-icon-class": _ctx.labelIconClass
                }, null, 8, ["label", "label-icon", "label-icon-class"]))
                : _createCommentVNode("", true),
            _createElementVNode("div", {
                class: _normalizeClass(["input-field", {
                        disabled: _ctx.disabled,
                        error: _ctx.error || !!_ctx.errorMessage.length,
                        success: _ctx.success,
                        transparent: _ctx.transparent,
                        focus: _ctx.focus,
                    }])
            }, [
                _createElementVNode("div", _hoisted_1, [
                    (_ctx.$slots.visual)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _renderSlot(_ctx.$slots, "visual")
                        ]))
                        : _createCommentVNode("", true),
                    _renderSlot(_ctx.$slots, "input")
                ]),
                (!_ctx.tooltipError)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (!!_ctx.$slots.message || !!_ctx.errorMessage.length)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(["validation-message", { error: _ctx.error || !!_ctx.errorMessage.length }])
                            }, [
                                (_ctx.errorMessage)
                                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.errorMessage, (message, i) => {
                                        return (_openBlock(), _createElementBlock("p", { key: i }, _toDisplayString(message), 1));
                                    }), 128))
                                    : _createCommentVNode("", true),
                                _renderSlot(_ctx.$slots, "message")
                            ], 2))
                            : _createCommentVNode("", true)
                    ], 64))
                    : _createCommentVNode("", true)
            ], 2)
        ]),
        _: 3
    }, 16, ["class"]));
}
