import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import SliderTooltip from "@/pages/orders/modals/brake/components/SliderTooltip.vue";
import NotificationComponent from "@/ui-elements/NotificationComponent.vue";
import CheckSwitch from "@/ui-elements/input/check-switch/CheckSwitch.vue";
export default defineComponent({
    name: "BrakeTab",
    components: {
        NotificationComponent,
        CheckSwitch,
        SliderTooltip,
    },
    props: {
        settings: Object,
    },
    setup(props, context) {
        const { t: translate } = useI18n();
        const sliderConfig = {
            min: 0,
            max: 50,
            interval: 5,
            dotSize: "22",
            height: 20,
            "drag-on-click": true,
            tooltip: "always",
            tooltipPlacement: "bottom",
            marks: [0, 10, 20, 30, 40, 50],
        };
        const minutesIncrement = 5;
        const updateBrakeSettings = (value, key) => context.emit("update:settings", {
            ...props.settings,
            [key]: value,
        });
        return {
            translate,
            sliderConfig,
            minutesIncrement,
            updateBrakeSettings,
        };
    },
    emits: ["update:settings"],
});
