import { computed, defineComponent, ref, toRaw, watch } from "vue";
import ModalWindow from "@/ui-elements/layout/modals/ModalWindow.vue";
import { useI18n } from "vue-i18n";
import OrdersMap from "@/pages/orders/modals/map/OrdersMap.vue";
import MapSideBar from "@/pages/orders/modals/map/MapSideBar.vue";
import { useDelivererStore } from "@/store/Deliverer";
import { useOrdersStore } from "@/store/Orders";
import { useOrderModalStore } from "@/store/OrderModal";
export default defineComponent({
    name: "OrdersMapModal",
    components: {
        MapSideBar,
        OrdersMap,
        ModalWindow,
    },
    props: {
        show: Boolean,
    },
    setup(props) {
        const _log = (message, context = "") => {
            if (window.ob.hasDebugModule("DelivererMap")) {
                console.log(`[DelivererMap] ${message}`, context);
            }
        };
        const { t: translate } = useI18n();
        const ordersStore = useOrdersStore();
        const orderModalStore = useOrderModalStore();
        const deliverers = computed(() => {
            if (orderModalStore.MapModalFilters.includes("deliverers")) {
                return useDelivererStore()
                    .deliverers.map((deliverer) => {
                    const orders = ordersStore.orders.filter((order) => order.deliverer_id === deliverer.id).length;
                    return {
                        ...deliverer,
                        n: orders,
                    };
                })
                    .filter((deliverer) => deliverer.n);
            }
            else {
                return [];
            }
        });
        const filteredOrders = computed(() => {
            const filteredByDelivery = toRaw(ordersStore.orders).filter((order) => order.delivery === "deliver" && order.coordinates);
            return filteredByDelivery.filter((order) => orderModalStore.MapModalFilters.includes(order.status));
        });
        function generateGeoJSON(array) {
            const features = array.map((entity) => ({
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: [...entity.coordinates.coordinates].reverse(),
                },
                properties: {
                    id: entity.id,
                    refnr: entity.refnr,
                },
            }));
            const collection = {
                type: "FeatureCollection",
                features: features,
            };
            return collection;
        }
        const ordersGeoJSON = computed(() => {
            return generateGeoJSON(filteredOrders.value);
        });
        const ordersCoordinates = computed(() => filteredOrders.value
            .filter((order) => order.coordinates)
            .map((order) => ({
            id: order.id,
            coord: order.coordinates.coordinates, //randomCoord(),
        })));
        const hoveredInSidebar = ref(0);
        const hoveredInMap = ref(0);
        const refreshDelivererInterval = ref(); // @todo, it seems it's not used?
        watch(() => props.show, () => {
            if (props.show) {
                _log("start polling deliverers every 5 seconds.");
                refreshDelivererInterval.value = setInterval(async () => {
                    _log("refreshing Deliverers.", {
                        success: await useDelivererStore().fetchDeliverers(),
                    });
                }, 10000);
            }
            else {
                _log("stop polling deliverers");
                clearInterval(refreshDelivererInterval.value);
            }
        });
        return {
            translate,
            orderModalStore,
            filteredOrders,
            deliverers,
            ordersCoordinates,
            hoveredInSidebar,
            hoveredInMap,
            ordersGeoJSON,
            ordersStore,
            refreshDelivererInterval,
        };
    },
});
