import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "data" };
const _hoisted_2 = { class: "header" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_PageTitle = _resolveComponent("PageTitle");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_PageTitle, {
            text: _ctx.data.title
        }, null, 8, ["text"]),
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.header, (value, name) => {
                    return (_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass(name),
                        key: name
                    }, _toDisplayString(value), 3));
                }), 128))
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.data, (line, lineName) => {
                return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(lineName),
                    key: lineName
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(line, (value, name) => {
                        return (_openBlock(), _createElementBlock("div", {
                            class: _normalizeClass(name),
                            key: name
                        }, _toDisplayString(value), 3));
                    }), 128))
                ], 2));
            }), 128))
        ])
    ], 64));
}
