import { computed, defineComponent, ref } from "vue";
import { useUserStore } from "@/store/User";
import IconBase from "@/ui-elements/IconBase.vue";
import { useClickAway } from "@/utils/useClickAway";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useSplitPaymentStore } from "@/store/SplitPayment";
import { setToastNotification } from "@/ui-elements/toast-notification/setToastNotification";
import { offlineModeStore } from "@/store/offlineMode";
import SelectComponent from "@/ui-elements/input/select/SelectComponent.vue";
import { multiLocationStore } from "@/store/MultiLocation";
export default defineComponent({
    name: "UserData",
    components: { IconBase, SelectComponent },
    props: {
        isSettings: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const isOffline = offlineModeStore().isOffline;
        const { t: translate } = useI18n();
        const router = useRouter();
        const userStore = useUserStore();
        const user_data = ref();
        const open = ref(false);
        const name = computed(() => useUserStore().user.address.company ||
            useUserStore().user.billing_address.company ||
            useUserStore().user.address.contact);
        const id = computed(() => "#" + useUserStore().user.id);
        const logo = computed(() => useUserStore().user.logo_url);
        useClickAway(user_data, () => (open.value = false));
        const splitPaymentStore = useSplitPaymentStore();
        const toggleMenu = () => {
            if (useUserStore().user.is_admin) {
                navigator.clipboard.writeText(`#${useUserStore().user.id} | ${useUserStore().user.address.company}`);
                setToastNotification(translate("success"), translate("client_name_and_id_copied_to_clipboard"), "success");
            }
            return isDisabled.value ? null : (open.value = !open.value);
        };
        const menuItems = computed(() => [
            {
                text: translate("account"),
                name: { path: "/settings/general/account" },
                icon: "cog",
            },
            {
                text: translate("logout"),
                name: { name: "logout" },
                icon: "unlock",
            },
        ]);
        const goTo = (route) => router.push(route);
        const openAdminInfo = () => {
            useUserStore().toggleAdminInfoModal();
            open.value = false;
        };
        const isDisabled = computed(() => isOffline || useSplitPaymentStore().isPartiallyPaid);
        return {
            isOffline,
            name,
            id,
            logo,
            open,
            user_data,
            menuItems,
            translate,
            router,
            goTo,
            userStore,
            openAdminInfo,
            splitPaymentStore,
            toggleMenu,
            isDisabled,
            multiLocation: multiLocationStore(),
        };
    },
});
