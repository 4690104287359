import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import NotificationComponent from "@/ui-elements/NotificationComponent.vue";
import CheckSwitch from "@/ui-elements/input/check-switch/CheckSwitch.vue";
export default defineComponent({
    name: "WebshopTab",
    components: {
        NotificationComponent,
        CheckSwitch,
    },
    props: {
        settings: Object,
    },
    setup() {
        const { t: translate } = useI18n();
        return {
            translate,
        };
    },
    emits: ["update:settings"],
});
