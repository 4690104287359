import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
    class: "country-field",
    ref: "field"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SelectComponent = _resolveComponent("SelectComponent");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_SelectComponent, {
            label: _ctx.translate('country'),
            value: _ctx.cartStore.customer.address.country,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event) => ((_ctx.cartStore.customer.address.country) = $event)),
            options: _ctx.countryOptions,
            ref: "inputField",
            name: "country"
        }, null, 8, ["label", "value", "options"])
    ], 512));
}
