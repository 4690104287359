import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
    class: "customer",
    ref: "boundingElement"
};
const _hoisted_2 = { class: "title" };
const _hoisted_3 = { class: "form" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_IconBase = _resolveComponent("IconBase");
    const _component_Tooltip = _resolveComponent("Tooltip");
    const _component_SearchableTextField = _resolveComponent("SearchableTextField");
    const _component_CountryField = _resolveComponent("CountryField");
    const _component_PhoneField = _resolveComponent("PhoneField");
    const _component_SelectComponent = _resolveComponent("SelectComponent");
    const _component_NotesField = _resolveComponent("NotesField");
    const _component_MailingField = _resolveComponent("MailingField");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Tooltip, {
            content: _ctx.translate('can_pay_by_invoice'),
            hover: _ctx.canPayByInvoice,
            arrow: ""
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                    _createTextVNode(_toDisplayString(_ctx.translate("customer_details")) + " ", 1),
                    (_ctx.canPayByInvoice)
                        ? (_openBlock(), _createBlock(_component_IconBase, {
                            key: 0,
                            class: "invoice-icon",
                            icon: "receipt"
                        }))
                        : _createCommentVNode("", true)
                ])
            ]),
            _: 1
        }, 8, ["content", "hover"]),
        _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_SearchableTextField, {
                label: _ctx.translate('company'),
                "customer-property": "company",
                "bounding-element": _ctx.boundingElement,
                name: "company"
            }, null, 8, ["label", "bounding-element"]),
            _createVNode(_component_SearchableTextField, {
                label: _ctx.translate('first_name'),
                "customer-property": "first_name",
                "bounding-element": _ctx.boundingElement,
                name: "first_name"
            }, null, 8, ["label", "bounding-element"]),
            _createVNode(_component_SearchableTextField, {
                label: _ctx.translate('last_name'),
                "customer-property": "last_name",
                "bounding-element": _ctx.boundingElement,
                name: "last_name"
            }, null, 8, ["label", "bounding-element"]),
            _createVNode(_component_SearchableTextField, {
                label: _ctx.translate('postcode'),
                "customer-property": "zipcode",
                "bounding-element": _ctx.boundingElement,
                name: "zipcode"
            }, null, 8, ["label", "bounding-element"]),
            _createVNode(_component_SearchableTextField, {
                label: _ctx.translate('house'),
                "customer-property": "street_number",
                "bounding-element": _ctx.boundingElement,
                name: "street_number"
            }, null, 8, ["label", "bounding-element"]),
            _createVNode(_component_SearchableTextField, {
                name: "address2",
                label: _ctx.translate('flat_number'),
                "bounding-element": _ctx.boundingElement,
                "customer-property": "address2"
            }, null, 8, ["label", "bounding-element"]),
            _createVNode(_component_SearchableTextField, {
                name: "address3",
                label: _ctx.translate('house_name'),
                "bounding-element": _ctx.boundingElement,
                "customer-property": "address3"
            }, null, 8, ["label", "bounding-element"]),
            _createVNode(_component_SearchableTextField, {
                label: _ctx.translate('street'),
                "customer-property": "street",
                "bounding-element": _ctx.boundingElement,
                name: "street",
                isLoading: _ctx.isAutoCompletingAddress && _ctx.isNL
            }, null, 8, ["label", "bounding-element", "isLoading"]),
            _createVNode(_component_SearchableTextField, {
                label: _ctx.translate('city'),
                "customer-property": "city",
                "bounding-element": _ctx.boundingElement,
                name: "city",
                isLoading: _ctx.isAutoCompletingAddress
            }, null, 8, ["label", "bounding-element", "isLoading"]),
            _createVNode(_component_CountryField),
            _createVNode(_component_PhoneField, {
                "bounding-element": _ctx.boundingElement,
                onValidation: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('phoneValidation', $event)))
            }, null, 8, ["bounding-element"]),
            _createVNode(_component_SearchableTextField, {
                label: _ctx.translate('email'),
                "customer-property": "email",
                "bounding-element": _ctx.boundingElement,
                name: "email"
            }, null, 8, ["label", "bounding-element"]),
            (_ctx.cartStore.customer.address.email)
                ? (_openBlock(), _createBlock(_component_SelectComponent, {
                    key: 0,
                    class: "w-full",
                    label: _ctx.translate('pos_modal_customer_data_discountCode'),
                    options: _ctx.discountCodes,
                    value: _ctx.cartStore.customer.discount,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event) => ((_ctx.cartStore.customer.discount) = $event))
                }, null, 8, ["label", "options", "value"]))
                : _createCommentVNode("", true),
            _createVNode(_component_NotesField),
            _createVNode(_component_MailingField)
        ])
    ], 512));
}
