import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_IconBase = _resolveComponent("IconBase");
    const _component_VueTelInput = _resolveComponent("VueTelInput");
    const _component_InputField = _resolveComponent("InputField");
    return (_openBlock(), _createBlock(_component_InputField, {
        class: "phone-input",
        disabled: _ctx.disabled,
        error: _ctx.error || !!_ctx.validationError,
        success: _ctx.success,
        focus: _ctx.opened,
        label: _ctx.label,
        name: _ctx.name,
        "label-icon": _ctx.labelIcon,
        "label-icon-class": _ctx.labelIconClass
    }, _createSlots({
        input: _withCtx(() => [
            _createVNode(_component_VueTelInput, {
                mode: "international",
                modelValue: _ctx.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => (_ctx.update($event))),
                onValidate: _ctx.onValidate,
                onFocus: _ctx.onFocus,
                onBlur: _ctx.onBlur,
                onOpen: _ctx.onFocus,
                onClose: _ctx.onBlur,
                inputOptions: _ctx.inputOptions,
                disabled: _ctx.disabled,
                defaultCountry: _ctx.defaultCountry
            }, {
                "arrow-icon": _withCtx(() => [
                    _createVNode(_component_IconBase, {
                        class: "arrow-icon",
                        icon: "chevron-down"
                    })
                ]),
                _: 1
            }, 8, ["modelValue", "onValidate", "onFocus", "onBlur", "onOpen", "onClose", "inputOptions", "disabled", "defaultCountry"])
        ]),
        _: 2
    }, [
        (_ctx.validationError)
            ? {
                name: "message",
                fn: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.validationError), 1)
                ]),
                key: "0"
            }
            : undefined
    ]), 1032, ["disabled", "error", "success", "focus", "label", "name", "label-icon", "label-icon-class"]));
}
