import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "browser-header grid grid-cols-2" };
const _hoisted_2 = { class: "overview" };
const _hoisted_3 = { class: "title" };
const _hoisted_4 = { class: "results" };
const _hoisted_5 = { class: "filters" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_TextField = _resolveComponent("TextField");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.translate("pictures")), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translate("app_result", {
                count: _ctx.number,
            })), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_TextField, {
                text: _ctx.search,
                "onUpdate:text": _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('update:search', $event))),
                icon: "search"
            }, null, 8, ["text"])
        ])
    ]));
}
