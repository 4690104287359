import { computed, defineComponent } from "vue";
import IconBase from "@/ui-elements/IconBase.vue";
import { useCartStore } from "@/store/cart/Cart";
import { addStrong, escapeHtml, } from "@/pages/pos/modal/customer/data/useSearchResults";
import { useCustomerModalStore } from "@/store/CustomerModal";
export default defineComponent({
    name: "SearchResult",
    components: {
        IconBase,
    },
    props: {
        result: { type: Object, required: true },
        property: {
            type: String,
            default: "",
        },
    },
    setup(props) {
        const cartStore = useCartStore();
        const customerStore = useCustomerModalStore();
        const highlightString = computed(() => {
            if (props.property === "") {
                return customerStore.search;
            }
            else {
                return cartStore.customer.address[props.property] || "";
            }
        });
        const name = computed(() => {
            return addStrong(highlightString.value, (props.result.address.company
                ? escapeHtml(props.result.address.company) + ", "
                : "") +
                (escapeHtml(props.result.address.first_name) || "") +
                " " +
                (escapeHtml(props.result.address.last_name) || ""));
        });
        const address = computed(() => {
            return addStrong(highlightString.value, escapeHtml(props.result.address.street) +
                " " +
                escapeHtml(props.result.address.street_number) +
                (props.result.address.address2
                    ? ", " + escapeHtml(props.result.address.address2) + " "
                    : "") +
                (escapeHtml(props.result.address.address3) || ""));
        });
        const city = computed(() => {
            return addStrong(highlightString.value, escapeHtml(props.result.address.zipcode) +
                " " +
                escapeHtml(props.result.address.city) +
                ", " +
                props.result.address.country);
        });
        const email = computed(() => addStrong(highlightString.value, escapeHtml(props.result.email)));
        const phone = computed(() => addStrong(highlightString.value, escapeHtml(props.result.phone)));
        const html = computed(() => {
            return `<div class="address"> ${name.value}, ${address.value}, ${city.value}</div> `;
        });
        return {
            html,
            email,
            phone,
        };
    },
});
