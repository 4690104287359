import { computed, defineComponent, ref } from "vue";
import { ButtonBase } from "@/ui-elements";
import { useI18n } from "vue-i18n";
export default defineComponent({
    name: "ButtonDeliverers",
    props: {
        showName: {
            type: Boolean,
            default: true,
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ButtonBase,
    },
    setup() {
        const { t: translate } = useI18n();
        const obOrdersOnly = ref(false);
        const active = computed(() => !obOrdersOnly.value);
        const showIcon = computed(() => active.value);
        return {
            translate,
            active,
            showIcon,
        };
    },
});
