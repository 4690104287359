import { onMounted, onUnmounted, ref, watch } from "vue";
const getScrollableDown = (area) => area.scrollHeight - area.scrollTop - area.clientHeight > 1;
const getScrollableUp = (area) => area.scrollTop > 0;
const getScrolledDown = (area) => area.scrollTop > 200;
const getScroll = (area, smooth, amount) => (direction) => area.scrollBy({
    top: amount * direction,
    left: 0,
    behavior: smooth ? "smooth" : undefined,
});
const getScrollToTop = (area) => () => area.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
});
export const useScroll = (scrollArea, singleScrollAmount = 110, constantScrollAmount = 30) => {
    const scrollableDown = ref(false);
    const scrollableUp = ref(false);
    const scrolledDown = ref(false);
    const setScroll = () => {
        if (scrollArea.value) {
            scrollableDown.value = getScrollableDown(scrollArea.value);
            scrollableUp.value = getScrollableUp(scrollArea.value);
            scrolledDown.value = getScrolledDown(scrollArea.value);
            if ((constantScrollDirection === 1 && !scrollableDown.value) ||
                (constantScrollDirection === -1 && !scrollableUp.value)) {
                stopConstantScroll();
            }
        }
    };
    const scroll = ref((direction) => { });
    const scrollToTop = ref(() => { });
    const constantScroll = ref((direction) => { });
    const setFunctions = () => {
        if (scrollArea.value) {
            scroll.value = getScroll(scrollArea.value, true, singleScrollAmount);
            scrollToTop.value = getScrollToTop(scrollArea.value);
            constantScroll.value = getScroll(scrollArea.value, false, constantScrollAmount);
        }
    };
    let interval = 0;
    const setInterval = () => {
        window.clearInterval(interval);
        interval = window.setInterval(() => {
            setScroll();
        }, 100);
    };
    let scrollInterval = 0;
    let constantScrollDirection = 0;
    const stopConstantScroll = () => {
        window.clearInterval(scrollInterval);
        constantScrollDirection = 0;
    };
    const startConstantScroll = (direction) => {
        stopConstantScroll();
        constantScrollDirection = direction;
        scrollInterval = window.setInterval(() => {
            constantScroll.value(direction);
        }, 16);
    };
    const init = () => {
        setFunctions();
        setInterval();
    };
    onMounted(() => {
        init();
    });
    onUnmounted(() => {
        window.clearInterval(interval);
    });
    watch(scrollArea, () => {
        init();
    });
    return {
        scrollableDown,
        scrollableUp,
        scrolledDown,
        scroll,
        scrollToTop,
        stopConstantScroll,
        startConstantScroll,
    };
};
