import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
export default defineComponent({
    name: "PasswordStrength",
    props: {
        password: {
            type: String,
            default: "",
        },
    },
    setup(props) {
        const { t: translate } = useI18n();
        const active = computed(() => props.password !== "");
        const passwordTests = computed(() => {
            let lowerCase = false;
            let upperCase = false;
            let digit = false;
            let password = props.password || "";
            password.split("").forEach((c) => {
                if (!isNaN(parseInt(c))) {
                    digit = true;
                }
                else {
                    if (c === c.toUpperCase()) {
                        upperCase = true;
                    }
                    if (c === c.toLowerCase()) {
                        lowerCase = true;
                    }
                }
            });
            return {
                lower_case: lowerCase,
                upper_case: upperCase,
                digit: digit,
                number: password.length >= 8,
            };
        });
        return {
            translate,
            passwordTests,
            active,
        };
    },
});
