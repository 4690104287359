import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "text" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createBlock(_Transition, { name: "bounce-in" }, {
        default: _withCtx(() => [
            (_ctx.VoipStore.callerIdActive && _ctx.VoipStore.callerId)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "top-bar caller-id cursor-pointer",
                    onClick: _cache[0] || (_cache[0] =
                        //@ts-ignore
                        (...args) => (_ctx.findCustomer && _ctx.findCustomer(...args)))
                }, [
                    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.translate("incoming_call_from")) + ": " + _toDisplayString(_ctx.VoipStore.callerId), 1)
                ]))
                : _createCommentVNode("", true)
        ]),
        _: 1
    }));
}
