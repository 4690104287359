import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["id"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingIndicator = _resolveComponent("LoadingIndicator");
    const _component_MapControls = _resolveComponent("MapControls");
    return (_openBlock(), _createElementBlock("div", {
        class: "route-map",
        id: _ctx.mapId
    }, [
        (_ctx.routing)
            ? (_openBlock(), _createBlock(_component_LoadingIndicator, {
                key: 0,
                cover: ""
            }))
            : _createCommentVNode("", true),
        _createVNode(_component_MapControls, {
            class: "controls-top-margin",
            map: _ctx.map,
            bounds: _ctx.bounds
        }, null, 8, ["map", "bounds"])
    ], 8, _hoisted_1));
}
