import { useOrderModalStore } from "@/store/OrderModal";
import { defineComponent, ref } from "vue";
import { ButtonBase } from "@/ui-elements";
import { useI18n } from "vue-i18n";
import OrdersFilter from "@/pages/orders/modals/map/OrdersFilter.vue";
import OrderTile from "@/pages/orders/order/components/OrderTile.vue";
import PageFooter from "@/ui-elements/layout/footer/PageFooter.vue";
import DelivererTile from "@/pages/orders/modals/order/tabs/DelivererTile.vue";
export default defineComponent({
    name: "MapSideBar",
    components: {
        OrdersFilter,
        ButtonBase,
        OrderTile,
        PageFooter,
        DelivererTile,
    },
    props: {
        statusFilter: {
            type: Array,
            required: true,
        },
        orders: {
            type: Array,
            required: true,
        },
        highlight: {
            type: Number,
            required: true,
        },
        deliverers: {
            type: Array,
            required: true,
        },
        ready: Boolean,
    },
    setup() {
        const orderModalStore = useOrderModalStore();
        const { t: translate } = useI18n();
        const scrollArea = ref();
        function updateMapModalFilters(statusFilter) {
            orderModalStore.updateStatusFilter(statusFilter);
        }
        return {
            translate,
            scrollArea,
            updateMapModalFilters,
        };
    },
    emits: ["close", "update:statusFilter", "hover"],
});
