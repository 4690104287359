import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_CheckSwitch = _resolveComponent("CheckSwitch");
    return (_openBlock(), _createBlock(_component_CheckSwitch, {
        class: "customer-mailing",
        label: _ctx.translate('mailing'),
        checked: _ctx.cartStore.customer.mailing,
        "onUpdate:checked": _cache[0] || (_cache[0] = ($event) => ((_ctx.cartStore.customer.mailing) = $event)),
        name: "mailing",
        "checked-label": _ctx.translate('yes'),
        "unchecked-label": _ctx.translate('no')
    }, null, 8, ["label", "checked", "checked-label", "unchecked-label"]));
}
