import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import StatusIcon from "@/pages/orders/StatusIcon.vue";
export default defineComponent({
    name: "ModalStatus",
    components: { StatusIcon },
    props: {
        status: {
            type: String,
        },
        delivery: {
            type: String,
        },
    },
    setup(props) {
        const { t: translate } = useI18n();
        const statusOptions = computed(() => [
            {
                label: translate("new"),
                value: "init",
            },
            {
                label: translate("kitchen"),
                value: "pending",
            },
            {
                label: translate("ready"),
                value: "pending-ready",
            },
            {
                label: translate("on_the_way"),
                value: "enroute",
            },
            {
                label: translate(props.delivery === "deliver" ? "delivered" : "picked_up"),
                value: "delivered",
            },
        ]);
        return {
            statusOptions,
        };
    },
    emits: ["update:status"],
});
