import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_CheckBox = _resolveComponent("CheckBox");
    const _component_SelectComponent = _resolveComponent("SelectComponent");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_CheckBox, {
            label: _ctx.translate('time'),
            checked: _ctx.store.currentCart.cart.schedule.asap,
            "onUpdate:checked": _cache[0] || (_cache[0] = ($event) => ((_ctx.store.currentCart.cart.schedule.asap) = $event)),
            description: _ctx.checkBoxDescription,
            disabled: _ctx.asapDisabled
        }, null, 8, ["label", "checked", "description", "disabled"]),
        (!_ctx.store.currentCart.cart.schedule.asap)
            ? (_openBlock(), _createBlock(_component_SelectComponent, {
                key: 0,
                value: _ctx.selectedTime,
                "onUpdate:value": _ctx.onTimeUpdate,
                disabled: _ctx.disabled,
                options: _ctx.timeIntervals,
                name: "time"
            }, null, 8, ["value", "onUpdate:value", "disabled", "options"]))
            : _createCommentVNode("", true)
    ], 64));
}
