<template>
    <div class="toast-content" data-testid="toast-notification">
        <div class="toast-description">
            <LoadingIndicator v-if="toastType === 'loading'" />
            <IconBase
                v-else
                :icon="icon"
                class="toast-icon"
                :class="toastType === 'success' ? 'success-icon' : 'error-icon'"
            ></IconBase>
            <div>
                <div class="description-title">{{ toastTitle }}</div>
                <div class="description-content" v-html="toastContent" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"
import { IconBase } from "@/ui-elements"
import "mosha-vue-toastify/dist/style.css"
import LoadingIndicator from "@/ui-elements/loaders/LoadingIndicator.vue"

export type ToastNotificationType = "danger" | "success"

export default defineComponent({
    name: "ToastNotification",
    components: {
        LoadingIndicator,
        IconBase,
    },
    props: {
        toastTitle: {
            type: String,
            required: true,
        },
        toastContent: {
            required: true,
        },
        toastType: {
            type: String as PropType<ToastNotificationType>,
            required: true,
        },
    },
    setup(props) {
        // eslint-disable-next-line vue/no-setup-props-destructure
        const icon = props.toastType === "success" ? "check-circle" : "error"

        return {
            icon,
        }
    },
})
</script>

<style lang="scss">
.mosha__toast {
    padding: $padding-m;

    .loading {
        flex: 0;
    }

    &__close-icon::before {
        top: -0.2rem;
        font-weight: 300;
        font-size: $font-size-h4;
        color: $black;
    }

    &.success {
        background-color: white;

        & .mosha__toast__progress {
            background: $gray;
            background-image: $secondary-gradient;
            top: 0;
            height: 0.15rem;
        }
    }

    &.danger {
        background-color: white;

        & .mosha__toast__progress {
            background: $gray;
            background-image: $primary-gradient;
            top: 0;
            height: 0.15rem;
        }
    }
}

.toast-content {
    .toast-title {
        color: $gray;
        font-size: $font-size-nav;
    }

    .toast-description {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: $margin-s;
        column-gap: $margin-m;

        .toast-icon {
            display: flex;
            align-items: center;
            font-size: 1.75rem;

            &.success-icon {
                color: $success-color;
            }

            &.error-icon {
                color: $error-color;
            }
        }

        .description-title {
            font-weight: $font-weight-medium;
            font-size: $font-size-nav;
        }

        .description-content {
            color: $gray;
            font-size: $font-size-nav;
        }
    }
}
</style>
