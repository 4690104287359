import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import IconBase from "@/ui-elements/IconBase.vue";
export default defineComponent({
    name: "SubMenu",
    components: { IconBase },
    props: {
        menuOptions: {
            type: Object,
            default: () => { },
        },
        translations: {
            type: Boolean,
            default: true,
        },
    },
    setup() {
        const { t: translate } = useI18n();
        const currentPage = computed(() => useRoute().meta.subSubName);
        return {
            translate,
            currentPage,
        };
    },
});
