import { computed, defineComponent, ref, watch } from "vue";
import { ModalWindow } from "@/ui-elements";
import { useI18n } from "vue-i18n";
import { ButtonBase, TextField } from "@/ui-elements";
import MessageSingle from "@/ui-elements/message-box/MessageSingle.vue";
import PageFooter from "@/ui-elements/layout/footer/PageFooter.vue";
import IconBase from "@/ui-elements/IconBase.vue";
import MessageView from "@/ui-elements/message-box/MessageView.vue";
import HorizontalBar from "@/ui-elements/layout/HorizontalBar.vue";
import { usePaginatedRequest } from "@/utils/api/usePaginatedRequest";
import { useOBMessagesStore } from "@/store/OBMessage";
import axios from "axios";
import LoadingIndicator from "@/ui-elements/loaders/LoadingIndicator.vue";
import { offlineModeStore } from "@/store/offlineMode";
export default defineComponent({
    name: "MessageModal",
    components: {
        HorizontalBar,
        MessageView,
        IconBase,
        ButtonBase,
        ModalWindow,
        TextField,
        MessageSingle,
        PageFooter,
        LoadingIndicator,
    },
    setup() {
        const { t: translate } = useI18n();
        const scrollArea = ref();
        const obMessagesStore = useOBMessagesStore();
        const initializeFilters = () => {
            return {
                search: "",
            };
        };
        const { list: messagesList, filterOptions, pagination, loading, } = usePaginatedRequest("/client/ob_messages", initializeFilters, [], ["search", "page", "perPage"], 10);
        const selectedIndex = ref(0);
        const disablePrevious = computed(() => selectedIndex.value <= 0);
        const disableNext = computed(() => selectedIndex.value >= messagesList.value.length - 1);
        const showPreviousMessage = () => {
            if (selectedIndex.value - 1 >= 0) {
                selectedIndex.value--;
            }
        };
        const showNextMessage = () => {
            if (selectedIndex.value + 1 <= messagesList.value.length - 1) {
                selectedIndex.value++;
            }
        };
        const markMessageAsRead = () => {
            // skip if offline
            if (offlineModeStore().isOffline) {
                return;
            }
            const message = messagesList.value[selectedIndex.value];
            if (!message) {
                return;
            }
            const wasRead = !obMessagesStore.unreadMessageIds.includes(message.id);
            if (wasRead) {
                return;
            }
            // mark message as read
            const url = "client/ob_messages/" + message.id + "/mark-as-read";
            axios
                .post(url)
                .then(() => {
                const messageIndex = obMessagesStore.unreadMessageIds.indexOf(message.id);
                obMessagesStore.unreadMessageIds.splice(messageIndex, 1);
            })
                .catch((e) => {
                console.error(e);
            });
        };
        watch([selectedIndex, messagesList], () => {
            markMessageAsRead();
        }, { immediate: true });
        return {
            translate,
            filterOptions,
            pagination,
            messagesList,
            scrollArea,
            selectedIndex,
            showPreviousMessage,
            disablePrevious,
            disableNext,
            obMessagesStore,
            showNextMessage,
            markMessageAsRead,
            loading,
        };
    },
});
