import { defineComponent, ref } from "vue";
import { toLongDateFormat } from "@/utils/useRefactorDate";
import { ButtonBase } from "@/ui-elements";
import { useI18n } from "vue-i18n";
import PageFooter from "@/ui-elements/layout/footer/PageFooter.vue";
export default defineComponent({
    name: "MessageView",
    components: {
        PageFooter,
        ButtonBase,
    },
    props: {
        message: {
            type: Object,
            required: true,
        },
        disablePreviousButton: {
            type: Boolean,
            required: true,
        },
        disableNextButton: {
            type: Boolean,
            required: true,
        },
    },
    setup(props, context) {
        const { t: translate } = useI18n();
        const scrollArea = ref();
        const scrollToTop = () => {
            scrollArea.value.scroll({ top: 0, behavior: "smooth" });
        };
        const toNextMessage = () => {
            scrollToTop();
            context.emit("toNextMessage");
        };
        const toPreviousMessage = () => {
            scrollToTop();
            context.emit("toPreviousMessage");
        };
        return {
            translate,
            scrollArea,
            toNextMessage,
            toPreviousMessage,
            toLongDateFormat,
        };
    },
    emits: ["update:selectedId", "toNextMessage", "toPreviousMessage"],
});
