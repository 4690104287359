import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import InfoTab from "@/pages/orders/modals/order/tabs/CustomerTab.vue";
import OrderTab from "@/pages/orders/modals/order/tabs/OrderTab.vue";
import RemarksTab from "@/pages/orders/modals/order/tabs/RemarksTab.vue";
import RoutePlannerTab from "@/pages/orders/modals/order/tabs/RoutePlannerTab.vue";
import DelivererTab from "@/pages/orders/modals/order/tabs/DelivererTab.vue";
import IconBase from "@/ui-elements/IconBase.vue";
import { useOrderModalStore } from "@/store/OrderModal";
export default defineComponent({
    name: "ModalTabs",
    components: {
        IconBase,
        InfoTab,
        OrderTab,
        RemarksTab,
        RoutePlannerTab,
        DelivererTab,
    },
    props: {
        order: {
            type: Object,
            required: true,
        },
        scrollAreaRef: Function,
    },
    setup(props) {
        const { t: translate } = useI18n();
        const orderModalStore = useOrderModalStore();
        const initialMenuOptions = orderModalStore.menuOptions;
        const menuOptions = computed(() => props.order.delivery === "deliver"
            ? initialMenuOptions
            : initialMenuOptions.slice(0, 3));
        const selectedTab = computed(() => {
            return menuOptions.value.find((option) => option.id === orderModalStore.openedTab);
        });
        const selectTab = (tabId) => {
            orderModalStore.openedTab = tabId;
        };
        return {
            translate,
            menuOptions,
            orderModalStore,
            selectedTab,
            selectTab,
        };
    },
});
