import { computed, defineComponent } from "vue";
import { useFormErrors } from "@/utils/useFormErrors";
import LabelComponent from "@/ui-elements/LabelComponent.vue";
import Tooltip from "@/ui-elements/Tooltip.vue";
export default defineComponent({
    name: "InputField",
    components: {
        Tooltip,
        LabelComponent,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        transparent: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Boolean,
            default: false,
        },
        success: {
            type: Boolean,
            default: false,
        },
        focus: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
        },
        name: {
            type: [Array, String],
            default: () => [],
        },
        labelIcon: {
            type: String,
        },
        labelIconClass: {
            type: String,
        },
        tooltipError: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { getErrorByName } = useFormErrors();
        const errorMessage = computed(() => {
            if (!props.name) {
                return [];
            }
            let messages = [];
            if (typeof props.name === "object") {
                messages = props.name
                    .map((name) => getErrorByName(name))
                    .flat(1);
            }
            else {
                messages = [getErrorByName(props.name)];
            }
            return messages.filter((message) => !!message);
        });
        const errorComponent = computed(() => props.tooltipError && errorMessage.value.length ? "Tooltip" : "div");
        const errorComponentConfig = computed(() => {
            if (props.tooltipError) {
                return {
                    hover: true,
                    arrow: true,
                    content: errorMessage.value.join(" "),
                    options: {
                        modifiers: {
                            preventOverflow: { escapeWithReference: true },
                        },
                    },
                };
            }
            else {
                return undefined;
            }
        });
        return {
            errorComponentConfig,
            errorMessage,
            errorComponent,
        };
    },
});
