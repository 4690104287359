import { defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { setFontSizeOnHTML, useFontSize } from "@/store/FontSize";
import { useClickAway } from "@/utils/useClickAway";
import IconBase from "@/ui-elements/IconBase.vue";
export default defineComponent({
    name: "FontSizeModifier",
    components: {
        IconBase,
    },
    setup() {
        const { t: translate } = useI18n();
        const modifier = ref();
        const open = ref(false);
        const sliderConfig = {
            height: 14,
            contained: true,
            dataValue: "value",
            dataLabel: "label",
            duration: 0.2,
            tooltip: "always",
            tooltipPlacement: "bottom",
            marks: (value) => ({
                label: [17, 21].includes(value) ? "Aa" : " ",
            }),
        };
        const sizeOptions = [
            {
                value: 10,
                label: "60%",
            },
            {
                value: 11,
                label: "65%",
            },
            {
                value: 12,
                label: "70%",
            },
            {
                value: 13,
                label: "75%",
            },
            {
                value: 14,
                label: "80%",
            },
            {
                value: 15,
                label: "85%",
            },
            {
                value: 16,
                label: "90%",
            },
            {
                value: 17,
                label: "95%",
            },
            {
                value: 18,
                label: "100%",
            },
            {
                value: 19,
                label: "105%",
            },
            {
                value: 20,
                label: "110%",
            },
            {
                value: 21,
                label: "115%",
            },
        ];
        const fontSize = useFontSize();
        const value = ref(fontSize.getSize());
        watch(value, () => {
            fontSize.setSize(value.value);
            setFontSizeOnHTML(value.value);
        });
        useClickAway(modifier, () => (open.value = false));
        return {
            translate,
            value,
            sizeOptions,
            sliderConfig,
            open,
            modifier,
        };
    },
});
