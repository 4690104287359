import { computed, defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import SettingsMenu from "@/ui-elements/menu/SettingsMenu.vue";
import { useI18n } from "vue-i18n";
import MenuLogo from "@/ui-elements/page-header/MenuLogo.vue";
import { RiceCooker } from "@/riceCooker/riceCooker";
import IconBase from "@/ui-elements/IconBase.vue";
import CallerId from "@/ui-elements/page-header/CallerId.vue";
import { useSettingsStore } from "@/store/Settings";
import { useUserStore } from "@/store/User";
import MobileMainMenu from "@/ui-elements/responsive/mobile/menu/MobileMainMenu.vue";
import UserData from "@/ui-elements/page-header/UserData.vue";
import { offlineModeStore } from "@/store/offlineMode";
import { networkIssuesStore } from "@/store/networkIssues";
export default defineComponent({
    name: "MobilePageHeader",
    methods: { networkIssuesStore, offlineModeStore },
    components: {
        IconBase,
        MenuLogo,
        SettingsMenu,
        CallerId,
        MobileMainMenu,
        UserData,
    },
    setup() {
        const { t: translate } = useI18n();
        const router = useRouter();
        const userStore = useUserStore();
        const settingStore = useSettingsStore();
        const settings = settingStore.settings;
        const showNav = ref(false);
        const isSettings = computed(() => {
            const regex = new RegExp("/settings");
            return ((router.currentRoute.value.matched.length &&
                router.currentRoute.value.matched[0].path ===
                    "/settings") ||
                regex.test(router.currentRoute.value.path));
        });
        const showFlyerImage = computed(() => router.currentRoute.value.name === "newFlyer");
        const RiceCookerPresent = RiceCooker.isPresent();
        const archive_mode = computed(() => !!Number(userStore.user.is_read_only));
        router.afterEach(() => {
            showNav.value = false;
        });
        return {
            showFlyerImage,
            translate,
            open,
            isSettings,
            RiceCooker,
            RiceCookerPresent,
            archive_mode,
            settings,
            showNav,
        };
    },
});
