import { computed, defineComponent } from "vue";
import { useRouter } from "vue-router";
import SettingsMenu from "@/ui-elements/menu/SettingsMenu.vue";
import { useI18n } from "vue-i18n";
import MenuLogo from "@/ui-elements/page-header/MenuLogo.vue";
import ControlButtons from "@/ui-elements/page-header/ControlButtons.vue";
import { RiceCooker } from "@/riceCooker/riceCooker";
import IconBase from "@/ui-elements/IconBase.vue";
import CallerId from "@/ui-elements/page-header/CallerId.vue";
import { useSettingsStore } from "@/store/Settings";
import { useUserStore } from "@/store/User";
import DesktopMainMenu from "@/ui-elements/responsive/desktop/menu/DesktopMainMenu.vue";
import { checkPath } from "@/ui-elements/menu/utils/useCheckPath";
import { offlineModeStore } from "@/store/offlineMode";
import { networkIssuesStore } from "@/store/networkIssues";
import { multiLocationStore } from "@/store/MultiLocation";
export default defineComponent({
    name: "DesktopPageHeader",
    methods: { networkIssuesStore, offlineModeStore },
    components: {
        IconBase,
        MenuLogo,
        SettingsMenu,
        ControlButtons,
        CallerId,
        DesktopMainMenu,
    },
    setup() {
        const { t: translate } = useI18n();
        const router = useRouter();
        const userStore = useUserStore();
        const settingStore = useSettingsStore();
        const showTbExternalOrderApiWarning = computed(() => Number(settingStore.settings.tb_api_active) &&
            settingStore.settings.tb_api_version !== "pos_api");
        const isSettings = computed(() => {
            const regex = new RegExp("/settings");
            return ((router.currentRoute.value.matched.length &&
                router.currentRoute.value.matched[0].path ===
                    "/settings") ||
                regex.test(router.currentRoute.value.path));
        });
        const showFlyerImage = computed(() => router.currentRoute.value.name === "newFlyer");
        const RiceCookerPresent = RiceCooker.isPresent();
        const archive_mode = computed(() => !!Number(userStore.user.is_read_only));
        const isKitchenScreen = computed(() => checkPath("/kitchen-screen"));
        return {
            showFlyerImage,
            translate,
            open,
            isSettings,
            RiceCooker,
            RiceCookerPresent,
            archive_mode,
            showTbExternalOrderApiWarning,
            isKitchenScreen,
            router,
            multiLocationStore,
        };
    },
});
