import { openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = ["src", "alt"];
const _hoisted_2 = {
    key: 1,
    class: "w-full h-full object-cover",
    autoplay: "",
    loop: ""
};
const _hoisted_3 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (!_ctx.src.endsWith('.mp4'))
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "w-full h-full object-cover",
            src: _ctx.src,
            alt: _ctx.getFileNameFormPath(_ctx.src)
        }, null, 8, _hoisted_1))
        : (_openBlock(), _createElementBlock("video", _hoisted_2, [
            _createElementVNode("source", {
                src: _ctx.src,
                type: "video/mp4"
            }, null, 8, _hoisted_3)
        ]));
}
