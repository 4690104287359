import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3e82b38b"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "message-view" };
const _hoisted_2 = {
    class: "message-content",
    ref: "scrollArea"
};
const _hoisted_3 = { class: "subject" };
const _hoisted_4 = { class: "created-date" };
const _hoisted_5 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ButtonBase = _resolveComponent("ButtonBase");
    const _component_PageFooter = _resolveComponent("PageFooter");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.message.subject), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.toLongDateFormat(_ctx.message.created_at)), 1),
            _createElementVNode("div", {
                class: "message",
                innerHTML: _ctx.message.message
            }, null, 8, _hoisted_5)
        ], 512),
        _createVNode(_component_PageFooter, { "scroll-area": _ctx.scrollArea }, {
            default: _withCtx(() => [
                _createVNode(_component_ButtonBase, {
                    onClick: _ctx.toPreviousMessage,
                    type: "regular",
                    variant: "outlined",
                    icon: "arrow-left",
                    disabled: _ctx.disablePreviousButton
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("previous_post")), 1)
                    ]),
                    _: 1
                }, 8, ["onClick", "disabled"]),
                _createVNode(_component_ButtonBase, {
                    onClick: _ctx.toNextMessage,
                    type: "regular",
                    variant: "outlined",
                    icon: "arrow-right",
                    disabled: _ctx.disableNextButton
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("next_post")), 1)
                    ]),
                    _: 1
                }, 8, ["onClick", "disabled"])
            ]),
            _: 1
        }, 8, ["scroll-area"])
    ]));
}
