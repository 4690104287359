import { computed, defineComponent, onMounted, ref, watch, } from "vue";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { getRoute } from "@/utils/Map/useRouting";
import { useMap } from "@/utils/Map/useMap";
import { drawDelivererMarker, drawFoodTicketMarker, drawOrderMarker, } from "@/utils/Map/useMapMarkers";
import { coordinateToLatLng } from "@/utils/useCoordinate";
import { useI18n } from "vue-i18n";
import LoadingIndicator from "@/ui-elements/loaders/LoadingIndicator.vue";
import MapControls from "@/ui-elements/MapControls.vue";
export default defineComponent({
    name: "RoutePlannerMap",
    components: {
        LoadingIndicator,
        MapControls,
    },
    props: {
        origin: {
            type: Object,
            required: true,
        },
        destination: {
            type: Object,
            required: true,
        },
        deliverer: {
            type: Object,
            required: false,
        },
        mode: {
            type: String,
            required: true,
        },
    },
    setup(props, context) {
        const { t: translate } = useI18n();
        const mapId = "orderRouteMap";
        const { map, addTiles, setMap, setBoundaries } = useMap(mapId);
        const isMapInitialised = ref(false);
        const bounds = ref();
        const mode = computed(() => props.mode);
        const coordinates = computed(() => {
            return [props.origin.coordinates, props.destination.coordinates];
        });
        const layerGroup = ref();
        const setupMap = (centerCoordinates, coordinateBounds = undefined) => {
            const centerLatLng = coordinateToLatLng(centerCoordinates);
            setMap(centerLatLng, coordinateBounds);
            addTiles();
            if (map.value) {
                map.value.createPane("route");
            }
            // get markers
            const markers = [];
            markers.push(drawFoodTicketMarker(coordinates.value[0])); // origin marker
            markers.push(drawOrderMarker(coordinates.value[1])); // destination marker
            if (props.deliverer && props.deliverer.coordinates) {
                markers.push(drawDelivererMarker(props.deliverer.coordinates.coordinates));
            }
            // add markers to the map
            if (map.value) {
                L.layerGroup(markers).addTo(map.value);
            }
            isMapInitialised.value = true;
        };
        const routing = ref(false);
        const buildRoute = async () => {
            routing.value = true;
            context.emit("routing", "");
            if (layerGroup.value && map.value) {
                // remove previous route layer
                map.value.removeLayer(layerGroup.value);
            }
            await getRoute(coordinates.value, props.mode)
                .then((response) => {
                const routeGeoJson = L.geoJSON(response.route, {
                    pane: "route",
                });
                if (response.summary) {
                    context.emit("routing", response.summary.formattedText);
                }
                // Add route to the map
                if (map.value) {
                    layerGroup.value = new L.LayerGroup()
                        .addTo(map.value)
                        .addLayer(routeGeoJson);
                }
                bounds.value = routeGeoJson.getBounds();
                setBoundaries(bounds.value);
            })
                .catch((e) => {
                context.emit("routing", translate("orders_route_not_possible"));
            })
                .finally(() => (routing.value = false));
        };
        onMounted(() => {
            setupMap(coordinates.value[0]);
            buildRoute();
        });
        watch(mode, buildRoute);
        return {
            mapId,
            routing,
            map,
            bounds,
        };
    },
    emits: ["routing"],
});
