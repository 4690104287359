import { computed, defineComponent, ref, toRef } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import TimeModal from "@/pages/orders/modals/time/TimeModal.vue";
import ButtonBase from "@/ui-elements/button-base/ButtonBase.vue";
import SelectComponent from "@/ui-elements/input/select/SelectComponent.vue";
import { useFormatCurrency } from "@/utils/useCurrencySymbol";
import { useSettingsStore } from "@/store/Settings";
import { useOrdersStore } from "@/store/Orders";
import { useClickToDial } from "@/utils/clickToDial/useClickToDial";
import { getPaymentMethodsEditable } from "@/pages/orders/utils/usePaymentMethods";
import { useOrderOptions } from "@/pages/archive/edit/utils/useOrderOptions";
import { storeToRefs } from "pinia";
import { fromUTC } from "@/utils/useTimeShift";
import { convertSecondsToHMS, useDateFormatters } from "@/utils/useRefactorDate";
import { isActuallyPaid, isPinFinal } from "@/utils/useIsOrderPaid";
import RoutePlannerMap from "@/pages/orders/modals/order/tabs/RoutePlannerMap.vue";
import { useUserStore } from "@/store/User";
import { multiLocationStore } from "@/store/MultiLocation";
export default defineComponent({
    name: "CustomerTab",
    components: {
        ButtonBase,
        TimeModal,
        SelectComponent,
        RoutePlannerMap,
    },
    props: {
        order: {
            type: Object,
            required: true,
        },
        scrollAreaRef: Function,
    },
    setup(props, context) {
        const { t: translate, n: numberFormat } = useI18n();
        const router = useRouter();
        const ordersStore = useOrdersStore();
        const { settings } = storeToRefs(useSettingsStore());
        const isModalOpened = ref(!!ordersStore.showTimeImmediately);
        const { formatDateTime } = useDateFormatters();
        const paymentMethodsEditable = computed(() => getPaymentMethodsEditable(props.order.payment.method));
        // eslint-disable-next-line vue/no-setup-props-destructure
        const { paymentMethodOptions } = useOrderOptions(toRef(props, "order"), props.order.payment?.method || "");
        const openCustomerPage = () => {
            if (props.order.customer_id) {
                router.push({
                    name: "customers/edit",
                    params: { id: props.order.customer_id },
                });
            }
            else {
                router.push({
                    name: "customers",
                    query: {
                        search: props.order.customer?.email ||
                            props.order.customer?.phone ||
                            props.order.customer?.last_name,
                    },
                });
            }
            ordersStore.isShowingOrderId = null;
        };
        const copyAddress = async () => {
            try {
                const address = `
                    ${props.order.address.street} ${props.order.address.street_number}
                    ${props.order.address.zipcode} ${props.order.address.city}
                `;
                await navigator.clipboard.writeText(address);
            }
            catch (error) {
                // Clipboard write failed
                // https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/writeText
            }
        };
        const timeSetLabel = computed(() => {
            if (props.order.time_set) {
                return props.order.time_set;
            }
            return `${props.order.delivery === "deliver"
                ? settings.value.delivery_min_time
                : settings.value.pickup_min_time} ${translate("minutes")}`;
        });
        const distanceDurationLabel = computed(() => {
            if (props.order.delivery === "deliver" && props.order.distance) {
                if (props.order.duration) {
                    return `(~${numberFormat(props.order.distance, "1-digit")} km / ${convertSecondsToHMS(props.order.duration)} min)`;
                }
            }
            return "";
        });
        const smsDisabled = computed(() => !Number(settings.value.sms_send) ||
            Boolean(Number(settings.value.sms_send_prohibited)));
        const toggleModal = () => {
            isModalOpened.value = !isModalOpened.value;
        };
        const updatePayment = (method) => {
            context.emit("update:order", {
                ...props.order,
                payment: { ...props.order.payment, method: method },
            });
        };
        const updateTime = (timeData) => {
            context.emit("update:order", timeData);
        };
        const { callsEnabled, dial } = useClickToDial();
        const makeCall = async () => {
            dial(props.order.phone);
        };
        const isActuallyUnpaid = computed(() => ["cash", "account"].includes(props.order.payment.method));
        const isCashOrder = computed(() => props.order.payment.method === "cash");
        return {
            translate,
            useFormatCurrency,
            openCustomerPage,
            copyAddress,
            timeSetLabel,
            distanceDurationLabel,
            toggleModal,
            isModalOpened,
            paymentMethodsEditable,
            paymentMethodOptions,
            updatePayment,
            updateTime,
            callsEnabled,
            makeCall,
            smsDisabled,
            fromUTC,
            isActuallyUnpaid,
            isCashOrder,
            convertSecondsToHMS,
            isActuallyPaid,
            isPinFinal,
            formatDateTime,
            userStore: useUserStore(),
            settingStore: useSettingsStore(),
            multiLocation: multiLocationStore(),
        };
    },
});
