import { computed, defineComponent } from "vue";
import { useFormatCurrency } from "@/utils/useCurrencySymbol";
import { parseProductExtras } from "@/utils/useProductExtras";
import { useSettingsStore } from "@/store/Settings";
export default defineComponent({
    name: "ProductRow",
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const settingsStore = useSettingsStore();
        const label = computed(() => {
            let returnValue = "";
            if (Number(settingsStore.settings.orderpopup_products_kitchentitle) &&
                props.product.product?.title?.extra) {
                returnValue = props.product.product?.title?.extra;
            }
            return (returnValue ||
                props.product.product?.title?.counter ||
                props.product.product?.title?.default ||
                props.product.description ||
                "");
        });
        const showSeparateExtras = computed(() => Number(settingsStore.settings.orderpopup_extras_array));
        return {
            useFormatCurrency,
            parseProductExtras,
            showSeparateExtras,
            label,
        };
    },
});
