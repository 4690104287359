import { computed, defineComponent } from "vue";
import DelivererTile from "@/pages/orders/modals/order/tabs/DelivererTile.vue";
import { useDelivererStore } from "@/store/Deliverer";
import { updateOrderStatus } from "@/pages/orders/order/utils/updateStatus";
import axios from "axios";
import { useOrdersStore } from "@/store/Orders";
import { setToastNotification } from "@/ui-elements/toast-notification/setToastNotification";
import { useI18n } from "vue-i18n";
export default defineComponent({
    name: "DelivererTab",
    components: {
        DelivererTile,
    },
    props: {
        order: {
            type: Object,
            required: true,
        },
        scrollAreaRef: Function,
    },
    setup(props, context) {
        const { t: translate } = useI18n();
        const delivererStore = useDelivererStore();
        const selectedDeliverer = computed(() => {
            return delivererStore.deliverers.find((deliverer) => deliverer.id === props.order.deliverer_id);
        });
        // @todo, code below calls endpoint for storing order with only the status (en route). This is not neeeded,
        // since it also calls the endpoint with status and deliverer id
        const selectDeliverer = async (delivererId) => {
            const ordersStore = useOrdersStore();
            const data = {
                deliverer_id: delivererId,
            };
            if (props.order.delivery === "deliver" && delivererId) {
                const save = (newOrder) => axios.patch("client/orders/" + ordersStore.isShowingOrderId, newOrder);
                const saveOrder = async (newOrder) => {
                    let success = false;
                    save(newOrder)
                        .then((response) => {
                        useOrdersStore().saveOrder(response.data.data);
                        success = true;
                    })
                        .catch(() => {
                        setToastNotification(translate("something_went_wrong"), translate("please_try_again"), "danger");
                    });
                    return success;
                };
                await updateOrderStatus("enroute", {
                    ...props.order,
                    ...data,
                }, saveOrder).then(() => {
                    data.status = "enroute";
                });
            }
            context.emit("update:order", data);
        };
        return {
            delivererStore,
            selectDeliverer,
            selectedDeliverer,
        };
    },
    emits: ["update:order"],
});
