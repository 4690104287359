import { computed, defineComponent } from "vue";
import IconBase from "@/ui-elements/IconBase.vue";
import { useI18n } from "vue-i18n";
import OrderPrice from "@/pages/orders/order/components/OrderPrice.vue";
import { useSettingsStore } from "@/store/Settings";
import DeliveryDetails from "@/pages/orders/order/components/DeliveryDetails.vue";
import { convertSecondsToHMS } from "@/utils/useRefactorDate";
import { useOrdersStore } from "@/store/Orders";
export default defineComponent({
    name: "OrderDetails",
    components: {
        OrderPrice,
        IconBase,
        DeliveryDetails,
    },
    props: {
        order: { type: Object, required: true },
        deliveryType: { type: Object },
        showButtons: {
            type: Boolean,
            default: false,
        },
        showRemarks: Boolean,
        showDeliverer: Boolean,
        isForMap: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { t: translate } = useI18n();
        const settings = useSettingsStore().settings;
        const showDuration = settings.order_show_disdur === "duration";
        const showRefNr = computed(() => settings.ob_show_refnr === "1");
        const orderLetter = useOrdersStore().getOrderLetter(props.order.id);
        return {
            translate,
            showDuration,
            showRefNr,
            orderLetter,
            convertSecondsToHMS,
        };
    },
});
