import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import IconBase from "@/ui-elements/IconBase.vue";
export default defineComponent({
    name: "DeliveryDetails",
    components: {
        IconBase,
    },
    props: {
        deliveryType: { type: Object },
    },
    setup() {
        const { t: translate } = useI18n();
        return { translate };
    },
});
