import { computed, defineComponent, toRef } from "vue";
import IconBase from "@/ui-elements/IconBase.vue";
import { getOrderSourceType } from "@/pages/orders/order/utils/getOrderSourceType";
import { useSettingsStore } from "@/store/Settings";
import { useI18n } from "vue-i18n";
import StatusIcon from "@/pages/orders/StatusIcon.vue";
import { fromUTC } from "@/utils/useTimeShift";
import { useOrderColor } from "@/utils/useOrderColor";
export default defineComponent({
    name: "OrderSource",
    components: {
        StatusIcon,
        IconBase,
    },
    props: {
        order: { type: Object, required: true },
        showRemarks: Boolean,
        showDeliverer: Boolean,
    },
    setup(props) {
        const { t: translate } = useI18n();
        const settings = useSettingsStore().settings;
        const toShortTime = (time) => {
            if (time === "asap") {
                return time.toUpperCase();
            }
            return time;
        };
        const orderSourceType = computed(() => getOrderSourceType(props.order.source.type, props.order.source.info));
        const { filter, background } = useOrderColor(toRef(props, "order"));
        return {
            translate,
            toShortTime,
            orderSourceType,
            settings,
            filter,
            background,
            fromUTC,
        };
    },
});
