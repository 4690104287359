import { renderSlot as _renderSlot, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Popper = _resolveComponent("Popper");
    return (_openBlock(), _createBlock(_component_Popper, _mergeProps(_ctx.$attrs, { class: "tooltip" }), {
        content: _withCtx((props) => [
            _renderSlot(_ctx.$slots, "content", _normalizeProps(_guardReactiveProps(props)), undefined, true)
        ]),
        default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 3
    }, 16));
}
