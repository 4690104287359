import { closest } from "@/utils/useClickAway";
import { ref } from "vue";
import { useEventListener } from "@vueuse/core";
export const calculateDirection = (boundingElement, field) => {
    if (boundingElement && field) {
        const spaceTop = field.getBoundingClientRect().top -
            boundingElement.getBoundingClientRect().top;
        const spaceBottom = boundingElement.getBoundingClientRect().bottom -
            field.getBoundingClientRect().bottom;
        return spaceBottom > spaceTop ? "down" : "up";
    }
    return "bottom";
};
export const calculateDisplay = (focussed, customer, customerProperty, results) => {
    return (focussed &&
        !!customer[customerProperty] &&
        //@ts-ignore
        customer[customerProperty].length > 2 &&
        results.length > 0);
};
export const eventInParent = (target, parent) => {
    if (target &&
        closest(target, (element) => element === parent.value)) {
        return true;
    }
    else {
        return false;
    }
};
export const highlightPositions = (searchString, text) => {
    const pos = text.toLowerCase().indexOf(searchString.toLowerCase());
    if (text && pos > -1) {
        const nextPositions = highlightPositions(searchString, text.slice(pos + 1)).map((element) => element + pos + 1);
        return [pos, ...nextPositions];
    }
    else {
        return [];
    }
};
export const recursiveFindAndReplace = (partOfText, positions, length) => {
    if (positions.length) {
        return (recursiveFindAndReplace(partOfText.slice(0, positions[0]), positions.slice(1), length) +
            "<strong>" +
            partOfText.slice(positions[0], positions[0] + length) +
            "</strong>" +
            partOfText.slice(positions[0] + length));
    }
    else {
        return partOfText;
    }
};
export const addStrong = (searchString, text) => {
    const positions = highlightPositions(searchString, text);
    return recursiveFindAndReplace(text, positions.reverse(), searchString.length);
};
export const escapeHtml = (text) => {
    return text
        ? text
            .replace(/&/g, "")
            .replace(/</g, "")
            .replace(/>/g, "")
            .replace(/"/g, "")
        : "";
};
export const useFocus = (focussed) => {
    const parent = ref();
    const focusHandler = (event) => {
        focussed.value = event.target
            ? eventInParent(event.target, parent)
            : false;
    };
    const blurHandler = (event) => {
        if (!eventInParent(event.relatedTarget, parent)) {
            focussed.value = false;
        }
    };
    useEventListener(parent, "focusin", focusHandler);
    useEventListener(parent, "focusout", blurHandler);
    return parent;
};
