import { computed, defineComponent, ref } from "vue";
import IconBase from "@/ui-elements/IconBase.vue";
import CheckSwitch from "@/ui-elements/input/check-switch/CheckSwitch.vue";
import FontSizeModifier from "@/ui-elements/page-header/FontSizeModifier.vue";
import { mainMenuStructure } from "@/pageStructure/mainMenuStructure";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useClickAway } from "@/utils/useClickAway";
import { useAPIStore } from "@/store/API";
import { disableSudoMode } from "@/services/auth/sudoMode";
import MessageModal from "@/ui-elements/message-box/MessageModal.vue";
import UserData from "@/ui-elements/page-header/UserData.vue";
import { usePosMenusStore } from "@/store/PosMenus";
import { useCartStore } from "@/store/cart/Cart";
import { usePOSStore } from "@/store/POS";
import { dataHydration } from "@/services/DataHydrationService";
import { useOBMessagesStore } from "@/store/OBMessage";
import LanguagePicker from "@/ui-elements/LanguagePicker.vue";
import { useUserStore } from "@/store/User";
import { checkPath } from "@/ui-elements/menu/utils/useCheckPath";
import { useOrdersStore } from "@/store/Orders";
import { useSplitPaymentStore } from "@/store/SplitPayment";
import ButtonBase from "@/ui-elements/button-base/ButtonBase.vue";
import { offlineModeStore } from "@/store/offlineMode";
import { updatesStore } from "@/store/Updates";
import { multiLocationStore } from "@/store/MultiLocation";
export default defineComponent({
    name: "DesktopMainMenu",
    components: {
        ButtonBase,
        IconBase,
        CheckSwitch,
        FontSizeModifier,
        MessageModal,
        UserData,
        LanguagePicker,
    },
    setup() {
        const { t: translate } = useI18n();
        const router = useRouter();
        const route = useRoute();
        const apiStore = useAPIStore();
        const userStore = useUserStore();
        const obMessages = useOBMessagesStore();
        const orderStore = useOrdersStore();
        const splitPaymentStore = useSplitPaymentStore();
        const futureOrderCount = computed(() => orderStore.futureOrderCount);
        // limit displayed label to 99. If over 99, show 99+
        const futureOrderCountLabel = computed(() => orderStore.futureOrderCount <= 99
            ? orderStore.futureOrderCount
            : "99+");
        const menuItems = computed(() => mainMenuStructure.filter((item) => {
            const archiveMode = !!Number(userStore.user.is_read_only);
            const addPos = !(item.name === "pos" && archiveMode);
            const addOrders = !(item.name === "orders" && archiveMode);
            return addPos && addOrders;
        }));
        const firstItems = computed(() => menuItems.value.slice(0, 4));
        const otherItems = computed(() => menuItems.value.slice(4));
        const dropdownOpen = ref(false);
        const dropdown_ref = ref();
        const currentRoute = computed(() => router.currentRoute.value.meta.name);
        const isKitchenScreen = computed(() => checkPath("/kitchen-screen"));
        const showFlyerImage = computed(() => router.currentRoute.value.name === "flyer");
        const otherSelected = computed(() => {
            return otherItems.value
                .map((_) => _.name)
                .includes(currentRoute.value);
        });
        useClickAway(dropdown_ref, () => (dropdownOpen.value = false));
        const toggleSudoMode = async (value) => {
            if (!value) {
                // disable sudo mode
                apiStore.disableSudoMode();
                await disableSudoMode();
                return;
            }
            // show pincode modal to enable sudo mode
            const APIStore = useAPIStore();
            APIStore.setRequirePincode(true).catch(() => { });
        };
        const posMenusStore = usePosMenusStore();
        const cartStore = useCartStore();
        const posStore = usePOSStore();
        const onClick = (routeName) => {
            if (splitPaymentStore.isPartiallyPaid) {
                return;
            }
            // reset the pos page when click on it the second time
            if (routeName === "pos" && route.name === "pos") {
                posStore.posBack();
                posMenusStore.resetSelection();
                posMenusStore.resetSelectedProduct();
                cartStore.clearCurrentCart();
            }
            // forces orders to load when clicking on order again
            if (routeName === "orders" && route.name === "orders") {
                dataHydration.hydrateModule("orders", true);
            }
        };
        return {
            isOffline: offlineModeStore().isOffline,
            firstItems,
            otherItems,
            currentRoute,
            translate,
            otherSelected,
            dropdownOpen,
            toggleSudoMode,
            apiStore,
            showFlyerImage,
            obMessages,
            futureOrderCount,
            futureOrderCountLabel,
            open,
            onClick,
            updatesStore,
            menuItems,
            splitPaymentStore,
            isKitchenScreen,
            multiLocation: multiLocationStore(),
        };
    },
});
