import { createApp, h, provide } from "vue"
import router from "@/router"
import OrderbuddyApp from "@/OrderbuddyApp.vue"
import i18n from "@/i18n"
import { createPinia } from "pinia"
import axios from "axios"
import VueAxios from "vue-axios"
import { setupAxios } from "@/api/axios"
import * as Sentry from "@sentry/vue"
import VueSlider from "vue-slider-component"
import { mountOrderBuddyWindowLogger } from "@/utils/Logging/OrderBuddyWindowLogger"
import { ApolloClients } from "@vue/apollo-composable"
import { dataBridgeApolloClient } from "@/utils/useAppollo"
import { createGtm } from "@gtm-support/vue-gtm"

mountOrderBuddyWindowLogger()
const app = createApp(OrderbuddyApp)

app.provide(ApolloClients, {
    default: dataBridgeApolloClient,
    dataBridge: dataBridgeApolloClient,
})

const sentryIntegrations = [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
]

if (
    process.env.VUE_APP_ENV !== "staging" &&
    process.env.VUE_APP_ENV !== "local"
) {
    sentryIntegrations.push(
        Sentry.feedbackIntegration({
            // Additional SDK configuration goes in here
            colorScheme: "system",
            buttonLabel: "Feedback",
            triggerLabel: "",
            submitButtonLabel: "Send Feedback",
            formTitle: "Send Feedback",
            enableScreenshot: true,
            showBranding: false,
        })
    )
}

Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_ENV,
    enabled: process.env.VUE_APP_ENV !== "local",
    release: process.env.VUE_APP_BITBUCKET_COMMIT,
    trackComponents: true,
    attachProps: true,
    normalizeDepth: 10,
    integrations: sentryIntegrations,
    tracePropagationTargets: [
        /^https:\/\/api\.test/,
        /^https:\/\/api\.foodticket\.dev/,
        /^https:\/\/api\.foodticket\.nl/,
    ],
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 0.1,
})

app.use(i18n)
    .use(createPinia())
    .use(router)
    .use(VueAxios, setupAxios(axios, router))
    .use(
        createGtm({
            id: "GTM-M8K2J78H",
            enabled: process.env.VUE_APP_ENV === "production",
            // @ts-ignore
            vueRouter: router,
        })
    )
app.provide("axios", app.config.globalProperties.axios)
app.component("VueSlider", VueSlider)
app.mount("#app")
