import * as Sentry from "@sentry/vue"
import { getHtml } from "@food-ticket/printhtml/src"
import { RiceCooker } from "@/riceCooker/riceCooker"
import { cloneDeep, round } from "lodash"
import { Order, VatRate } from "@/interface/orders/Order"
import { useSettingsStore } from "@/store/Settings"
import { ReceiptLayout } from "@/interface/settings/printers/Receiptlayout"
import { useUserStore } from "@/store/User"
import { PrintType, usePrintersStore } from "@/store/Printers"
import { useCustomerReceiptStore } from "@/store/CustomerReceiptConfirmation"
import axios, { AxiosResponse } from "axios"
import { useOrdersStore } from "@/store/Orders"
import { useSplitPaymentStore } from "@/store/SplitPayment"
import {
    SplitPaymentPerson,
    SplitPaymentProduct,
    SplitPaymentSplitType,
} from "@/interface/pos/SplitPaymentPerson"
import { useFormatCurrency } from "@/utils/useCurrencySymbol"
import i18n from "@/i18n"
import { OrderProduct } from "@/interface/orders/OrderProduct"
import { OrderPayment } from "@/interface/orders/Payment"
import { getLocalPrinterStorageKey } from "@/pages/settings/general/printers/receipt-layouts/utils/useLocalPrinters"
import { computed, ComputedRef, Ref, ref, UnwrapRef } from "vue"
import { markTableOrderAsPrinted } from "@/store/cart/cartOrderUtils"
import { multiLocationStore } from "@/store/MultiLocation"
import type { Settings } from "@/store/Settings"

export type PrintableObject = {
    order: any
    settings: any
    client: any
    discount?: any | null
    currency: string
}

export type PrintOrderOptions = {
    printCustomerReceipts: undefined | boolean
    force: boolean
}

const _log = (message: string) => {
    if (window.ob.hasDebugModule("Printer") || RiceCooker.isPresent()) {
        console.log(`[PrinterService] ${message}`)
    }
}

export function print(html: string, copies: number = 1) {
    // Make sure that we are in a riceCooker env before executing anything else...
    if (!RiceCooker.isPresent()) {
        return printBrowser(html)
    }

    // Fetch the default printer from the printer store and make sure it exists...
    const defaultPrinter = usePrintersStore().defaultPrinter()
    if (!defaultPrinter) {
        return
    }

    // Execute the riceCooker printer...
    return printRiceCooker(defaultPrinter.printer, html, copies)
}

export async function openDrawer(printerId?: number): Promise<void> {
    if (!printerId) {
        const settings: UnwrapRef<Settings> = useSettingsStore().settings
        printerId = Number(settings.pos_printer_drawer)
    }

    if (!printerId || !RiceCooker.isPresent()) {
        return
    }

    const printer: ReceiptLayout | undefined =
        usePrintersStore().printerData(printerId)

    if (!printer) {
        return
    }

    await window.riceCooker.openDrawer({
        printer: printer.printer,
    })
}

export async function printOrderWithPrinter(
    order: Order,
    printer: ReceiptLayout,
    copies: number = 1,
    isPreview: boolean = false
): Promise<void> {
    const { settings } = useSettingsStore()
    const printable: PrintableObject = cloneDeep({
        order,
        settings: printer,
        client: useUserStore().user,
        currency: settings.ob_currency,
    })

    const actualPrinter: ComputedRef<string> = computed(
        () =>
            localStorage.getItem(getLocalPrinterStorageKey(printer.id)) ??
            printer.printer
    )
    const printDefault: Ref<boolean> = ref(false)

    const settingsStore = useSettingsStore()

    // Override the css logo if none is present.
    if (!printable.settings?.printer_css_logo) {
        printable.settings.printer_css_logo = settingsStore.settings.css_logo

        if (
            multiLocationStore().isActive &&
            multiLocationStore().useLocationPrinters
        ) {
            printable.settings.printer_css_logo =
                multiLocationStore().location_settings[
                    order.client_id
                ]?.css_logo
        }
    }

    const location: {
        address: {
            company: string
            contact: string
            address: string
            zipcode: string
            city: string
            state?: string | null
            country: string
            phone: string
            email: string
        }
        preferred_domain: string | null
        vat: string | null
    } = locationSettings(printable, order.client_id)

    const html: string | string[] =
        getHtml(
            printable.order,
            printable.settings,
            printable.discount,
            {
                currency: printable.currency,
                print_labels_separate: Boolean(
                    Number(settings.print_labels_separate)
                ),
                print_escpos_in_browser: RiceCooker.isPresent(),
                payment_vat: Number(settingsStore.settings.payment_vat),
                locale: localStorage.getItem("localLocale") || "nl",
            },
            location.address,
            location.vat,
            location.preferred_domain
        ) ?? []

    _log(
        `Order #${order.id}, printer: ${actualPrinter.value}, copies: ${
            Number(copies) || 1
        } HTML generated`
    )

    console.log(
        `Order #${order.id}, printer: ${actualPrinter.value}, copies: ${
            Number(copies) || 1
        } HTML generated`
    )

    // if contains no products/categories anymore after filtering, skip
    if (printable.order.products.length === 0 && !isPreview) {
        _log(
            `[PrinterService] Order #${order.id}, printer: ${
                actualPrinter.value
            }, copies: ${
                Number(copies) || 1
            } was NOT printed (all products filtered out)`
        )

        console.log(
            `[PrinterService] Order #${order.id}, printer: ${
                actualPrinter.value
            }, copies: ${
                Number(copies) || 1
            } was NOT printed (all products filtered out)`
        )

        return
    }

    const defaultPrinter: ReceiptLayout | undefined =
        usePrintersStore().defaultPrinter(order.client_id)
    // if printer is empty, then use default printer
    if (RiceCooker.isPresent() && actualPrinter.value === null) {
        printDefault.value = true
    }

    if (printDefault.value && !defaultPrinter) {
        _log(
            `[PrinterService] Order #${order.id}, printer: ${
                actualPrinter.value
            }, copies: ${
                Number(copies) || 1
            } was NOT printed (no default printer)`
        )

        console.log(
            `[PrinterService] Order #${order.id}, printer: ${
                actualPrinter.value
            }, copies: ${
                Number(copies) || 1
            } was NOT printed (no default printer)`
        )

        return
    } else {
        if (defaultPrinter) {
            _log(
                `Order #${order.id}, printer: ${actualPrinter.value}, redirected to default printer: ${defaultPrinter.printer}`
            )

            console.log(
                `Order #${order.id}, printer: ${actualPrinter.value}, redirected to default printer: ${defaultPrinter.printer}`
            )
        }
    }

    let htmls: string[] = []
    if (typeof html === "string") {
        htmls.push(html)
    } else {
        htmls = html
    }

    if (!htmls || htmls.length === 0) {
        _log("there are no html strings to print")
        console.log("there are no html strings to print")
        return
    }

    htmls.forEach((html: string) => {
        if (!RiceCooker.isPresent()) {
            return printBrowser(html)
        }

        if (!printDefault.value) {
            return printRiceCooker(actualPrinter.value, html, copies)
        }

        if (defaultPrinter) {
            return printRiceCooker(defaultPrinter.printer, html, copies)
        }
    })
}
const updateOrderStatus = (orderId: number, status: string) =>
    axios
        .patch("client/orders/" + orderId, {
            status,
        })
        .then((response: AxiosResponse<any>) =>
            useOrdersStore().saveOrder(response.data.data)
        )

export const locationSettings = (
    printable: PrintableObject,
    clientId: number
): {
    address: {
        company: string
        contact: string
        address: string
        zipcode: string
        city: string
        state?: string | null
        country: string
        phone: string
        email: string
    }
    preferred_domain: string | null
    vat: string | null
} => {
    let location: {
        address: {
            company: string
            contact: string
            address: string
            zipcode: string
            city: string
            state?: string | null
            country: string
            phone: string
            email: string
        }
        preferred_domain: string | null
        vat: string | null
    } = {
        address: printable.client.address,
        vat: printable.client.billing_address.vat,
        preferred_domain: printable.client.preferred_domain,
    }

    if (
        multiLocationStore().isActive &&
        multiLocationStore().useLocationPrinters
    ) {
        location = {
            address:
                multiLocationStore().location(clientId)?.address ??
                printable.client.address,
            vat:
                multiLocationStore().location(clientId)?.billing_address?.vat ??
                null,
            preferred_domain:
                multiLocationStore().location(clientId)?.preferred_domain ??
                null,
        }
    }

    return location
}

export async function printOrder(
    originalOrder: Order,
    options: PrintOrderOptions = {
        printCustomerReceipts: undefined,
        force: false,
    }
): Promise<void> {
    // Do not print if this is Waiterbuddy, the master will print
    if (useUserStore().waiterbuddy_platform) {
        return
    }

    // Since we're going to remove products, we need a copy
    const order: Order = cloneDeep(originalOrder)

    const receiptType: PrintType = options.printCustomerReceipts
        ? "customer"
        : "normal" // Being the non-customer receipts

    // If order is already printed, and not a waiter buddy or table order
    // do not print again, unless it is forced.
    if (
        !options.force &&
        usePrintersStore().isOrderAlreadyPrinted(order.id, receiptType) &&
        !order.table_id
    ) {
        _log(
            `Order was already automatically printed for receipt type: ${receiptType}, and printing was not forced, skipping...`
        )
        console.log(
            `Order was already automatically printed for receipt type: ${receiptType}, and printing was not forced, skipping...`
        )
        return
    }

    const { settings } = useSettingsStore()

    if (settings.print_set_status_after) {
        await updateOrderStatus(order.id, settings.print_set_status_after)
    }

    if (order.id === useSplitPaymentStore().order?.id) {
        order.payment.method = "pin"
        order.payment.method_name = i18n.global.t("payment_splitted")
        order.payment.status = "paid"
        order.payment.status_name = i18n.global.t("paid")
    }

    // If this is a table order, and source.url contains an order_product id
    // we need to filter out all the products that were already printed.
    if (
        order.table_id &&
        Number(order.source.url) &&
        !order.has_seen &&
        !options.printCustomerReceipts
    ) {
        // Special case: table order was updated, but no products were added
        if (Number(order.source.url) === -1) {
            order.products = []
        } else {
            order.products = order.products.filter(
                (product: OrderProduct): boolean =>
                    Number(product.id) >= Number(order.source.url)
            )
        }
    }

    // Below we procedurally determine which receipt needs to be printed
    let neededReceiptType: string | null = !options.printCustomerReceipts
        ? "order_print"
        : null

    let shouldPrintCustomer: string = settings.print_customer
    if (order.table_id) {
        neededReceiptType = options.printCustomerReceipts
            ? "table_customer_print"
            : "table_print"

        shouldPrintCustomer = settings.print_customer_table
    } else if (["kiosk", "counter"].includes(order.source.type)) {
        neededReceiptType = options.printCustomerReceipts
            ? "counter_customer_print"
            : "counter_print"
    }

    if (!neededReceiptType) {
        _log(
            "printOrder(): No receipts are configured for #" +
                order.id +
                " (source: " +
                order.source.type.valueOf() +
                ", customer receipt needed: " +
                options.printCustomerReceipts +
                ")"
        )

        console.log(
            "printOrder(): No receipts are configured for #" +
                order.id +
                " (source: " +
                order.source.type.valueOf() +
                ", customer receipt needed: " +
                options.printCustomerReceipts +
                ")"
        )

        return
    }

    const printersToUse: Array<ReceiptLayout> | undefined = selectPrinters(
        order.client_id
    )

    let printers: Array<ReceiptLayout> | undefined =
        usePrintersStore().selectPrintersForReceipt(
            printersToUse,
            neededReceiptType!
        )

    // If it's a counter order, and it was an existing one, only use the first POS printer
    if (
        neededReceiptType === "counter_customer_print" &&
        printers !== undefined
    ) {
        printers = printers.splice(0, 1)
    }

    // No printers were selected
    if ((printers === undefined || !printers.length) && !shouldPrintCustomer) {
        _log(
            "printOrder(): No printers are configured for #" +
                order.id +
                " (" +
                options.printCustomerReceipts +
                ")"
        )

        console.log(
            "printOrder(): No printers are configured for #" +
                order.id +
                " (" +
                options.printCustomerReceipts +
                ")"
        )

        return
    }

    if (printers !== undefined) {
        printers.forEach((printer: ReceiptLayout) => {
            const copies: number = usePrintersStore().selectQuantityForReceipt(
                printer,
                neededReceiptType!
            )

            _log(
                `Order #${order.id}, printer: ${printer.name}, copies: ${copies}, mode: ${printer.mode}`
            )

            console.log(
                `Order #${order.id}, printer: ${printer.name} (#${printer.id}, client: ${printer.client_id}), type: ${neededReceiptType}, copies: ${copies}, mode: ${printer.mode}`
            )

            if (
                order.table_id &&
                order.payment.status === "paid" &&
                !options.printCustomerReceipts &&
                !options.force
            ) {
                // If source.url contains an order_product id, it means we still have unprinted products
                if (!order.source.url && order.has_seen) {
                    _log(
                        `Skipping non-customer receipts for finished table order with id #${order.id}`
                    )

                    console.log(
                        `Skipping non-customer receipts for finished table order with id #${order.id}`
                    )
                    return
                }
            }

            // If we make it until here, we are going to print
            return printOrderWithPrinter(order, printer, copies).then(
                (): void => {}
            )
        })

        if (
            order.table_id &&
            // If customer receipt is printed, and there are still pending products for the kitchen receipt,
            // do not put at seen
            !(order.source.url && options.printCustomerReceipts)
        ) {
            await markTableOrderAsPrinted(order)
            _log(`Marked table order with id #${order.id} as printed`)

            console.log(`Marked table order with id #${order.id} as printed`)
        }

        usePrintersStore().markOrderAsPrinted(order.id, receiptType)

        console.log(`Marked order with id #${order.id} as printed`)
    }

    if (!["counter", "kiosk", "qr_table"].includes(order.source.type)) {
        return
    }

    if (
        options.printCustomerReceipts !== undefined &&
        useSplitPaymentStore().order?.id !== order.id
    ) {
        return
    }

    if (useSplitPaymentStore().order?.id === order.id) {
        // Always print SplitPayment customer receipts!
        await printSplitPaymentOrder(originalOrder)

        return
    }

    if (order.table_id && order.payment.status !== "paid") {
        return
    }
}

export async function printCustomerReceipts(order: Order) {
    const { settings } = useSettingsStore()
    let shouldPrintCustomer: string = settings.print_customer
    if (order.table_id) {
        shouldPrintCustomer = settings.print_customer_table
    }

    if (
        useOrdersStore().isWaiterBuddy(order) ||
        useOrdersStore().isQrTable(order)
    ) {
        await printOrder(order, {
            printCustomerReceipts: true,
            force: false,
        })
        return
    }

    // Print customer receipts, but not for kiosk, since the kiosk already prints it
    if (order.source.type !== "kiosk") {
        if (shouldPrintCustomer === "auto") {
            await printOrder(order, {
                printCustomerReceipts: true,
                force: false,
            })
        } else if (shouldPrintCustomer === "ask") {
            const customerReceiptStore = useCustomerReceiptStore()
            customerReceiptStore
                .askConfirmation()
                .then(async () => {
                    await printOrder(order, {
                        printCustomerReceipts: true,
                        force: true,
                    })
                })
                .catch(() => {})
        }
    }
}

// For multi location, normally the main location's printers are used, unless a settings indicates otherwise
function selectPrinters(locationId: number): Array<ReceiptLayout> | undefined {
    let printersToUse: Array<ReceiptLayout> | undefined =
        usePrintersStore().printers // These are the main location's printers
    if (
        multiLocationStore().isActive &&
        multiLocationStore().useLocationPrinters
    ) {
        printersToUse = multiLocationStore().printers(locationId)
    }

    return printersToUse
}

export async function printSplitPaymentOrder(order: Order) {
    for (
        let index: number = 0;
        index < useSplitPaymentStore().persons.length;
        index++
    ) {
        await printSplitPaymentCustomerReceipts(
            order,
            index,
            useSplitPaymentStore().splitType
        )
    }
}

export async function printSplitPaymentCustomerReceipts(
    order: Order,
    SplitPaymentPersonIndex: number,
    SplitPaymentType: SplitPaymentSplitType
) {
    const { t: translate } = i18n.global
    const settings: UnwrapRef<Settings> = useSettingsStore().settings
    const person: UnwrapRef<SplitPaymentPerson> | undefined =
        useSplitPaymentStore().persons.at(SplitPaymentPersonIndex)

    const printersToUse: ReceiptLayout[] | undefined = selectPrinters(
        order.client_id
    )
    const originalReceiptPrinter: ReceiptLayout[] =
        usePrintersStore().selectPrintersForReceipt(
            printersToUse,
            "counter_customer_print"
        )

    if (
        originalReceiptPrinter === undefined ||
        originalReceiptPrinter.at(0) === undefined ||
        !person
    ) {
        return false
    }

    const receiptPrinter: ReceiptLayout | undefined = cloneDeep(
        originalReceiptPrinter.at(0)
    )

    if (receiptPrinter === undefined) {
        return
    }

    const copies: number = usePrintersStore().selectQuantityForReceipt(
        <ReceiptLayout>originalReceiptPrinter.at(0),
        "counter_customer_print"
    )

    console.log(
        `Order split payment #${order.id}, printer: ${receiptPrinter?.name} (#${receiptPrinter?.id}, client: ${receiptPrinter.client_id}), copies: ${copies}, mode: ${receiptPrinter?.mode}`
    )

    const orderCopy: Order = cloneDeep(order)

    await useCustomerReceiptStore()
        .askConfirmation()
        .then(async (): Promise<boolean> => {
            // Split by amount
            if (SplitPaymentType === SplitPaymentSplitType.Amount) {
                const totalVat: number = orderCopy.vat_rates.reduce(
                    (totalVat: number, vatRate: VatRate) =>
                        (totalVat += vatRate.total),
                    0
                )
                const vatRatio: number =
                    totalVat * (person.total / orderCopy.total)

                receiptPrinter.content.end_text.text = translate(
                    "split_by_amount_receipt_line",
                    {
                        totalPaid: useFormatCurrency(person.total),
                        vatPaid: useFormatCurrency(vatRatio),
                    }
                )

                orderCopy.payment2.amount = undefined
                orderCopy.payment2.method = undefined
                orderCopy.payment2.method_name = undefined
                orderCopy.payment.method =
                    person.paymentMethod as OrderPayment["method"]
                orderCopy.payment.method_name = translate(
                    person.paymentMethod ?? "cash"
                )
                orderCopy.payment.status = "paid"
                orderCopy.payment.status_name = translate("paid")

                await printOrderWithPrinter(
                    orderCopy,
                    receiptPrinter,
                    Number(copies)
                )
                return true
            } else if (SplitPaymentType === SplitPaymentSplitType.Product) {
                // Split by product
                const personProducts: any[] = person.products.reduce(
                    (OrderProductIds: any[], product: SplitPaymentProduct) => {
                        if (product.orderProductId) {
                            OrderProductIds.push(product.orderProductId)
                        }
                        return OrderProductIds
                    },
                    []
                )

                const personOrderProducts: OrderProduct[] = orderCopy.products
                    .filter((orderProduct: OrderProduct) => {
                        return personProducts?.includes(orderProduct.id)
                    })
                    .map((orderProduct: OrderProduct) => {
                        orderProduct.quantity = Number(
                            person.products.find(
                                (product: SplitPaymentProduct) =>
                                    product.orderProductId === orderProduct.id
                            )?.selectedQuantity
                        )
                        orderProduct.total =
                            orderProduct.price_unit * orderProduct.quantity

                        return orderProduct
                    })

                orderCopy.products = personOrderProducts

                // Adjust payment info
                orderCopy.payment.method =
                    person.paymentMethod as OrderPayment["method"]
                orderCopy.payment.method_name = translate(
                    person.paymentMethod ?? "cash"
                )
                orderCopy.payment.status = "paid"
                orderCopy.payment.status_name = translate("paid")
                orderCopy.total = person.total
                orderCopy.subtotal = person.total
                orderCopy.total_netto = person.total
                orderCopy.total_rounded = person.total

                orderCopy.payment2.amount = undefined
                orderCopy.payment2.method = undefined
                orderCopy.payment2.method_name = undefined

                // Adjust vat
                const vatRates = personOrderProducts.reduce(
                    (vatRates: any, orderProduct: OrderProduct) => {
                        if (!Number(vatRates[orderProduct.vat.rate])) {
                            vatRates[orderProduct.vat.rate] = {
                                amount: 0,
                                total: 0,
                            }
                        }

                        const vat: number = Number(
                            (orderProduct.total / 100) *
                                (settings.vat_rates.at(
                                    orderProduct.vat.rate - 1 ?? 0
                                ) ?? 0)
                        )
                        vatRates[orderProduct.vat.rate].amount +=
                            orderProduct.total - vat
                        vatRates[orderProduct.vat.rate].total += vat
                        vatRates[orderProduct.vat.rate].rate =
                            settings.vat_rates.at(
                                orderProduct.vat.rate - 1 ?? 0
                            )

                        return vatRates
                    },
                    []
                )

                orderCopy.vat_rates = orderCopy.vat_rates.map(
                    (rate: VatRate) => {
                        const correspondingNewVat = vatRates.find(
                            (vatRate: VatRate) => vatRate?.rate === rate.rate
                        ) ?? { amount: 0, total: 0, rate: null }
                        rate.amount = round(correspondingNewVat.amount, 2)
                        rate.total = round(correspondingNewVat.total, 2)

                        return rate
                    }
                )

                // Packaging costs disclaimer on receipt
                receiptPrinter.content.end_text.text = translate(
                    "split_by_product_packaging_costs_info"
                )

                await printOrderWithPrinter(
                    orderCopy,
                    receiptPrinter,
                    Number(copies)
                )

                return true
            } else {
                return false
            }
        })
        .catch((e: any): void => {
            Sentry.captureException(e)
        })
}

export function printRiceCooker(
    printer: string,
    html: string,
    copies: number = 1,
    mode: "html" | "escpos" = "html"
) {
    console.log(`[PrinterService] Sent to RC`)
    return window.riceCooker.print({
        printer_id: printer,
        prints: [{ mode, data: html, copies }],
    })
}

export function printBrowser(html: string) {
    const iframe: HTMLIFrameElement = document.createElement("iframe")
    iframe.style.display = "none"
    iframe.srcdoc = html
    document.body.appendChild(iframe)

    if (iframe.contentWindow) {
        iframe.contentWindow.addEventListener("load", () => {
            iframe.contentWindow?.print()
            setTimeout(() => iframe.remove(), 1000)
        })
    }
}
