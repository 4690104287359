import * as d3 from "d3";
import { ref, computed, defineComponent } from "vue";
import SVGDefs from "@/ui-elements/SVGDefs.vue";
import { tweenOnMount } from "@/utils/useTween";
import LoadingIndicator from "@/ui-elements/loaders/LoadingIndicator.vue";
import { formatNumber } from "@/utils/useNumberFormatting";
export default defineComponent({
    name: "DonutDisplay",
    components: {
        SVGDefs,
        LoadingIndicator,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const tween = ref({ t: 0.0 });
        tweenOnMount(tween, 1500, 500, "CubicOut");
        const radius = 50;
        const arcGenerator = d3
            .arc()
            .startAngle(0)
            .innerRadius(radius - 5)
            .outerRadius(radius)
            .cornerRadius(2);
        const circle = computed(() => arcGenerator({
            endAngle: 2 * Math.PI,
        }));
        const arc = computed(() => arcGenerator({
            endAngle: 2 * Math.PI * props.data.percent * tween.value.t,
        }));
        return {
            radius,
            circle,
            arc,
            tween,
            formatNumber,
        };
    },
});
